// React
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// React Bootstrap
import {
    Button,
    Form,
    Row,
    Col,
    FloatingLabel,
    Breadcrumb,
    Card,
    Container
} from "react-bootstrap";

// Icone
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

// Service
import api from "../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Outros
import { errorMensagem } from "../../styled";

const validaForm = yup.object().shape({
    nome: yup.string().required("Favor informe o nome do pessoa"),
    ultimo_nome: yup.string().required('Favor informe o ultimo nome da pessoa'),
    email: yup.string().required('Favor informe o email da pessoa'),
});

function FormEditarUsuario(){
    const { id_usuario } = useParams();
    const [projetos, setProjetos] = useState([]);
    let history = useHistory();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validaForm)
    });

    useEffect(() => {
        api.get(`/usuario/${ id_usuario }`)
        .then(response => {
            const data = response.data.usuario;

            reset(data);
        })
        .catch(error => console.log(error));

        const data = {
            nome: ''
        };

        api.post("/projeto", data)
        .then(response => {
            const data = response.data.projetos;

            setProjetos(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const edtUsuarioProjeto = (data) => { 
        api.patch("/usuario/editar", data)
        .then( (res) => { 
            var msg = res.data.mensagem;
            alert(msg);
            history.push('/usuarios');
        })
        .catch( (err) => { 
            var msg = "Contate o administrador do sistema. \n\n ";
            msg += err.response.data.mensagem;
            alert(msg)
        })
    };

    return (
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Editar Usuário</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/usuarios">Usuários</Breadcrumb.Item>
                        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Row>
                <Card >
                    <Card.Body>
                        <Card.Text>Formulário para editar informações dos pesquisadores e vinculo com projeto.</Card.Text> 
                        
                        <Form onSubmit={handleSubmit(edtUsuarioProjeto)}> 
                            <Form.Control type="hidden" id="funcao" name="funcao" value="pesquisador"  {...register("funcao")} />

                            <Row>
                                <Col md={6}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="nome" 
                                            label="Nome">
                                            <Form.Control type="text" name="nome" {...register("nome")} placeholder="nome"/>
                                            <p style={ errorMensagem }>{ errors.nome?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="ultimo_nome" 
                                            label="Ultimo Nome">
                                            <Form.Control type="text" name="ultimo_nome" {...register("ultimo_nome")} placeholder="ultimo_nome"/>
                                            <p style={ errorMensagem }>{ errors.ultimo_nome?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="email" 
                                            label="Email">
                                            <Form.Control type="text" name="email" disabled={true} {...register("email")} placeholder="email"/>
                                            <p style={ errorMensagem }>{ errors.email?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                                <Col md={4}>
                                    <Form.Floating>
                                        <FloatingLabel label="Selecione um Projeto">
                                            <Form.Select aria-label="Selecione"  {...register("id_projeto")}>
                                                {
                                                    projetos.map((projeto, key) => {
                                                        return (
                                                            <option key={key} value={projeto.id_projeto} { ...register("id_projeto") === projeto.id_projeto ? 'selected' : '' }>{ projeto.nome }</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    * A senha padrão é <b>dietbase@123</b>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={{ span: 4 }}>
                                    <Button id="my-btn-primary" style={{width: '100%'}} className="btn-fill pull-right" type="submit" >
                                        <FontAwesomeIcon icon={faSave} /> Atualizar
                                    </Button>
                                </Col>
                            </Row>
                            <div className="clearfix"></div>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default FormEditarUsuario;
