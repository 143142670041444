import { useEffect, useState } from "react";

import { 
    Button,
    Form,
    Row,
    Col,
    FloatingLabel,
    Container,
    Breadcrumb,
    Card,
    Table,
    Alert
 } from "react-bootstrap";

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faCloudDownload } from '@fortawesome/free-solid-svg-icons'

// Serviços
import api from "../../services/api";

// Outros
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUsuario } from "../../config/auth";
import { composicaoAlimentar } from "../../constants";
import { btnFull, colAcao, errorMensagem } from "../../styled";

const validaForm = yup.object().shape({
});

function FormRelatorio2(){
    const [projetos, setProjetos] = useState([]);
    const [relatorios, setRelatorios] = useState([]);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });


    const gerar = (data) => {
        data.id_projeto = (data.id_projeto === '') ? projetos[0].id_projeto : data.id_projeto;
        
        api.post("/relatorio/gerarCSV", data)
        .then( (resp) => { 
            var msg = resp.data.mensagem;
            alert(msg);
            document.location.reload();
        })
        .catch( (err) => { 
            var msg = "Contate o administrador do sistema. \n\n ";
            console.log(err)
            msg += err.response.data.mensagem;
            alert(msg)
        })   
    }
    
    useEffect(() => {
        const data = {
            nome: ""
        };

        api.post("/projeto", data)
        .then(response => {
            const data = response.data.projetos;
            {
                var proj = (getUsuario().funcao === 'admin' || getUsuario().funcao === 'orientador') ? data : data.filter(projeto => projeto.id_projeto === getUsuario().id_projeto);
                setProjetos(proj)
            }
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        api.post("/relatorio")
        .then(response => {
            const data = response.data.relatorios;
            setRelatorios(data)
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    return (
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Relatórios</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Relatórios</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Card>
                <Card.Body>
                    <Card.Text>Selecione os parâmetros com base no relatório que desejar gerar.</Card.Text>

                    <Form onSubmit={handleSubmit(gerar)}> 
                        <Row>
                            <Col md={12}>
                                <Form.Floating>
                                    <FloatingLabel label="Selecione um Projeto">
                                        <Form.Select aria-label="Selecione"  {...register("id_projeto")}>
                                            {
                                                projetos.map((projeto, key) => {
                                                    return (
                                                        <option key={key} value={projeto.id_projeto} >{ projeto.nome }</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <p>Périodo que deseja gerar o relatório</p>
                            <Col md={6}>
                                <Form.Floating>
                                    <FloatingLabel 
                                        id="dt_inicio" 
                                        label="Data Inicio">
                                        <Form.Control type="date" name="dt_inicio" {...register("dt_inicio")} placeholder="dt_inicio"/>
                                        <p style={ errorMensagem }>{ errors.dt_inicio?.message }</p>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Col>
                            <Col md={6}>
                                <Form.Floating>
                                    <FloatingLabel 
                                        id="dt_fim" 
                                        label="Data Fim">
                                        <Form.Control type="date" name="dt_fim" {...register("dt_fim")} placeholder="dt_fim"/>
                                        <p style={ errorMensagem }>{ errors.dt_fim?.message }</p>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row>
                            <p>Lista de Composição Alimentar</p>
                            {
                                composicaoAlimentar.map((item) => {
                                    return (
                                        <Col md={3}>
                                            <Form.Group className="mb-3" controlId={item.id}>
                                                <Form.Check type="checkbox" label={item.label} {...register(item.id)} />
                                            </Form.Group>
                                        </Col>
                                    )
                                })
                            }
                            
                        </Row>
                        <br />
                        <Row>
                            <Col md={{ span: 12 }}>
                                <Button id="my-btn-primary" style={ btnFull } className="btn-fill pull-right" type="submit" >
                                    <FontAwesomeIcon icon={faFileExcel} /> Gerar CSV
                                </Button>
                            </Col>
                        </Row>
                        <div className="clearfix"></div>
                    </Form>
                </Card.Body>
            </Card>
            <br/>
            <br/>
            <Card >
                <Card.Header>
                    <Card.Title>Relatórios gerados</Card.Title>
                </Card.Header>
                <Card.Body>
                    {
                        (relatorios.length >= 1) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th style={ colAcao }>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        relatorios.map((relatorio, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{ relatorio.nome }</td>
                                                    
                                                    <td style={ colAcao }>
                                                        <Button id="my-btn-primary" className="btn-fill pull-right" href={relatorio.url} type="submit" >
                                                            <FontAwesomeIcon icon={faCloudDownload} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        :
                            <Alert key='warning' variant='warning'>
                                Não possui relatórios gerados
                            </Alert>
                    }
                </Card.Body>
            </Card>
            <br/>
        </Container>
    )
}

export default FormRelatorio2;