import logo from './logo.svg';
import './App.css';
import './assets/css/my-style.css'

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./view/login/login";
import EsqueciSenha from "./view/login/esqueciSenha";
import Perfil from "./view/login/perfil";
import Dashboard from "./view/dashboard";
import Projetos from "./view/projetos/listaProjetos";
import AddProjeto from "./view/projetos/formAdicionarProjeto";
import EdtProjeto from "./view/projetos/formEditarProjeto";
import Usuarios from "./view/usuarios/listaUsuarios";
//import ListaUsuarioProjeto from "./view/usuarios/listaUsuarios";
import AddUsuarioProjeto from "./view/usuarios/formAdicionarUsuario";
import EdtUsuarioProjeto from "./view/usuarios/formEditarUsuario";
import Pessoas from "./view/pessoas/listaPessoas";
import AddPessoa from "./view/pessoas/formAdicionarPessoa";
import EdtPessoa from "./view/pessoas/formEditarPessoa";
import Recordatorios from "./view/recordatorios/listaRecordatorios";
import AddRecordatorioRefeicao from "./view/recordatorios/formRecordatorioRefeicao";
import EdtRecordatorioRefeicao from "./view/recordatorios/formEditarRecordatorioRefeicao";
import Alimentos from "./view/alimentos/listaAlimentos";
import AddAlimento from "./view/alimentos/formAdicionarAlimento";
import EdtAlimento from "./view/alimentos/formEditarAlimento";
import Receitas from "./view/receitas/listaReceitas";
//import AddReceita from "./view/receitas/formAdicionarReceita";
//import EdtReceita from "./view/receitas/formEditarReceita";
import ListAlimentoReceita from "./view/receitas/listaAlimentosReceita";
import Relatorio from "./view/relatorios/formRelatorio2";
import Faq from "./view/faq/listaFaq";
//import { logout, ac }  from "../../config/auth";
import { useAuthStore } from "./config/store";

import Menu from "./view/layout/menu";
import Footer from "./view/layout/footer2";

function App() {
  const jwt = useAuthStore((state) => state.jwt);
  return (
    <Router>
      { jwt!==null && jwt !=="" && (
        <Menu />
      )}
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/projetos" component={Projetos}/>
        <Route exact path="/addProjeto" component={AddProjeto}/>
        <Route exact path="/edtProjeto/:id_projeto" component={EdtProjeto}/>
        <Route exact path="/usuarios" component={Usuarios}/>
        <Route exact path="/usuarios/addUsuarioProjeto" component={AddUsuarioProjeto}/>
        <Route exact path="/usuarios/edtUsuarioProjeto/:id_usuario" component={EdtUsuarioProjeto}/>
        <Route exact path="/pessoas" component={Pessoas}/>
        <Route exact path="/addPessoa" component={AddPessoa}/>
        <Route exact path="/edtPessoa/:id_pessoa" component={EdtPessoa}/>
        <Route exact path="/recordatorios/:id_pessoa/:dia" component={Recordatorios}/>
        <Route exact path="/recordatorios/:id_pessoa/:dia/refeicao" component={AddRecordatorioRefeicao}/>
        <Route exact path="/recordatorios/:id_pessoa/:dia/:refeicao" component={EdtRecordatorioRefeicao}/>
        <Route exact path="/alimentos" component={Alimentos}/>
        <Route exact path="/addAlimento" component={AddAlimento}/>
        <Route exact path="/edtAlimento/:id_alimento" component={EdtAlimento}/>
        <Route exact path="/receitas" component={Receitas}/>
        <Route exact path="/listAlimentoReceita/:id_receita" component={ListAlimentoReceita}/>
        <Route exact path="/relatorios" component={Relatorio}/>
        <Route exact path="/faq" component={Faq}/>
        <Route exact path="/esqueciSenha" component={EsqueciSenha}/>
        <Route exact path="/perfil" component={Perfil}/>
      </Switch>

    </Router>
  );
}

export default App;
