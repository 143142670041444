// React
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// React Bootstrap
import {
    Button,
    Form,
    Row,
    Col,
    FloatingLabel,
    Breadcrumb,
    Card,
    Container
} from "react-bootstrap";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

// Serviçes
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { errorMensagem } from "../../styled";

const validaForm = yup.object().shape({
    nome: yup.string().required("Favor informe o nome do projeto"),
    descricao: yup.string().typeError('Favor informe a descrição').required()
});

function FormEditarProjeto(){
    const { id_projeto } = useParams();
    let history = useHistory();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validaForm)
    });
    
    const edtProjeto = (data) => { 
        api.patch("/projeto/editar", data)
        .then( (res) => { 
            var msg = res.data.mensagem;
            alert(msg);
            history.push("/projetos");
        })
        .catch( (err) => { 
            var msg = "Contate o administrador do sistema. \n\n ";
            msg += err.response.data.mensagem;
            alert(msg)
        })
    };

    useEffect(() => {
        api.get(`/projeto/${ id_projeto }`)
        .then((response) => {
            reset(response.data.projeto)
        });

    }, []);

    return (
        <Container> 
            < br />
            <Row>
                <Col md={6}>
                    <h2>Editar Projeto</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/projetos">Projetos</Breadcrumb.Item>
                        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Row>
                <Card >
                    <Card.Body>
                        <Card.Text>Formulário para editar informações das pessoas que aceitram participar da pesquisa.</Card.Text>
                        
                        <Form onSubmit={handleSubmit(edtProjeto)}> 
                            <Row>
                                <Col md={12}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            controlId="nome" 
                                            label="Nome do Projeto">
                                            <Form.Control type="text" name="nome" {...register("nome")} />
                                            <p style={ errorMensagem }>{ errors.nome?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FloatingLabel 
                                        id="descricao" 
                                        label="Descrição"
                                    >
                                        <Form.Control
                                            as="textarea"
                                            name="descricao" {...register("descricao")} 
                                            style={{ height: '100px' }}
                                        />
                                        <p style={ errorMensagem }>{ errors.descricao?.message }</p>
                                    </FloatingLabel>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            controlId="num_recordatorios" 
                                            label="Num. Recordatórios">
                                            <Form.Control name="num_recordatorios" {...register("num_recordatorios")} type="number"/>
                                            <p style={ errorMensagem }>{ errors.num_recordatorios?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 4 }}>
                                    <Button id="my-btn-primary" style={{width: '100%'}} className="btn-fill pull-right" type="submit" >
                                        <FontAwesomeIcon icon={faSave} /> Atualizar
                                    </Button>
                                </Col>
                            </Row>
                            <div className="clearfix"></div>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default FormEditarProjeto;
