// React
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

// React Bootstrap
import {
    Button,
    Form,
    Row,
    Col,
    FloatingLabel,
    Breadcrumb,
    Card,
    Container
} from "react-bootstrap";

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

// Serviços
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { optionsGruposAlimentos } from '../../constants';
import { errorMensagem } from "../../styled";

const validaForm = yup.object().shape({
    desc_alimento: yup.string().required("Favor informe o nome do alimento"),
    qtde: yup.string().required('Favor informe a quantidade do alimento')
});

function FormAdicionarAlimento(){
    let history = useHistory();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });
    
    const addAlimento = (data) => { 
        
        data.qtde = (data.qtde != "") ? parseFloat((data.qtde).replace(",", ".")) : "";
        data.energia_kcal = (data.energia_kcal != "") ? parseFloat((data.energia_kcal).replace(",", ".")) : "";
        data.proteina = (data.proteina != "") ? parseFloat((data.proteina).replace(",", ".")) : "";
        data.lipideos = (data.lipideos != "") ? parseFloat((data.lipideos).replace(",", ".")) : "";
        data.saturada = (data.saturada != "") ? parseFloat((data.saturada).replace(",", ".")) : "";
        data.monoinsat = (data.monoinsat != "") ? parseFloat((data.monoinsat).replace(",", ".")) : "";
        data.polinsat = (data.polinsat != "") ? parseFloat((data.polinsat).replace(",", ".")) : "";
        data.omega_6 = (data.omega_6 != "") ? parseFloat((data.omega_6).replace(",", ".")) : "";
        data.omega_3 = (data.omega_3 != "") ? parseFloat((data.omega_3).replace(",", ".")) : "";
        data.colesterol = (data.colesterol != "") ? parseFloat((data.colesterol).replace(",", ".")) : "";
        data.cho = (data.cho != "") ? parseFloat((data.cho).replace(",", ".")) : "";
        data.fibras = (data.fibras != "") ? parseFloat((data.fibras).replace(",", ".")) : "";
        data.calcio = (data.calcio != "") ? parseFloat((data.calcio).replace(",", ".")) : "";
        data.mg = (data.mg != "") ? parseFloat((data.mg).replace(",", ".")) : "";
        data.mn = (data.mn != "") ? parseFloat((data.mn).replace(",", ".")) : "";
        data.p = (data.p != "") ? parseFloat((data.p).replace(",", ".")) : "";
        data.ferro = (data.ferro != "") ? parseFloat((data.ferro).replace(",", ".")) : "";
        data.sodio = (data.sodio != "") ? parseFloat((data.sodio).replace(",", ".")) : "";
        data.potassio = (data.potassio != "") ? parseFloat((data.potassio).replace(",", ".")) : "";
        data.cobre = (data.cobre != "") ? parseFloat((data.cobre).replace(",", ".")) : "";
        data.zinco = (data.zinco != "") ? parseFloat((data.zinco).replace(",", ".")) : "";
        data.retinol = (data.retinol != "") ? parseFloat((data.retinol).replace(",", ".")) : "";
        data.tiamina = (data.tiamina != "") ? parseFloat((data.tiamina).replace(",", ".")) : "";
        data.riboflavina = (data.riboflavina != "") ? parseFloat((data.riboflavina).replace(",", ".")) : "";
        data.piridoxina = (data.piridoxina != "") ? parseFloat((data.piridoxina).replace(",", ".")) : "";
        data.niacina = (data.niacina != "") ? parseFloat((data.niacina).replace(",", ".")) : "";
        data.vit_c = (data.vit_c != "") ? parseFloat((data.vit_c).replace(",", ".")) : "";
        data.equiv_niacina = (data.equiv_niacina != "") ? parseFloat((data.equiv_niacina).replace(",", ".")) : "";
        data.cobalamina = (data.cobalamina != "") ? parseFloat((data.cobalamina).replace(",", ".")) : "";
        data.equiv_folato = (data.equiv_folato != "") ? parseFloat((data.equiv_folato).replace(",", ".")) : "";
        data.vit_d = (data.vit_d != "") ? parseFloat((data.vit_d).replace(",", ".")) : "";
        data.vit_e = (data.vit_e != "") ? parseFloat((data.vit_e).replace(",", ".")) : "";
        data.trans = (data.trans != "") ? parseFloat((data.trans).replace(",", ".")) : "";
        data.sodio_adicao = (data.sodio_adicao != "") ? parseFloat((data.sodio_adicao).replace(",", ".")) : "";
        data.selenio = (data.selenio != "") ? parseFloat((data.selenio).replace(",", ".")) : "";
        data.vit_a = (data.vit_a != "") ? parseFloat((data.vit_a).replace(",", ".")) : "";
        data.acucar_total = (data.acucar_total != "") ? parseFloat((data.acucar_total).replace(",", ".")) : "";
        data.acucar_adicao = (data.acucar_adicao != "") ? parseFloat((data.acucar_adicao).replace(",", ".")) : "";

        api.post("/alimento/cadastrar", data)
        .then( (res) => { 
            var msg = res.data.mensagem;
            alert(msg);
            history.push("/alimentos");
        })
        .catch( (err) => { 
            var msg = err.response.data.mensagem;
            alert(msg)
        })
        /*
        */
    };

    return (
        <Container> 
            < br />
            <Row>
                <Col md={6}>
                    <h2>Alimentos</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/alimentos">Alimentos</Breadcrumb.Item>
                        <Breadcrumb.Item active>Adicionar</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Row>
                <Card >
                    <Card.Body>
                        <Card.Text>Formulário para criar alimentos com base em sua composição.</Card.Text>
            
                            <Form onSubmit={handleSubmit(addAlimento)}> 
                                <Row>
                                    <Col md={6}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="desc_alimento" 
                                                label="Nome do Alimento">
                                                <Form.Control type="text" name="desc_alimento" {...register("desc_alimento")} placeholder="desc_alimento"/>
                                                <p style={ errorMensagem }>{ errors.desc_alimento?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="qtde" 
                                                label="Quantidade (g)">
                                                <Form.Control type="text" name="qtde" {...register("qtde")} placeholder="qtde"/>
                                                <p style={ errorMensagem }>{ errors.qtde?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="energia_kcal" 
                                                label="Energia (KCal)">
                                                <Form.Control type="text" name="energia_kcal" {...register("energia_kcal")} placeholder="energia_kcal"/>
                                                <p style={ errorMensagem }>{ errors.energia_kcal?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="proteina" 
                                                label="Proteína (g)">
                                                <Form.Control type="text" name="proteina" {...register("proteina")} placeholder="proteina"/>
                                                <p style={ errorMensagem }>{ errors.proteina?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="lipideos" 
                                                label="Lipídeos (g)">
                                                <Form.Control type="text" name="lipideos" {...register("lipideos")} placeholder="lipideos"/>
                                                <p style={ errorMensagem }>{ errors.lipideos?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="saturada" 
                                                label="Ácidos graxos saturados (g)">
                                                <Form.Control type="text" name="saturada" {...register("saturada")} placeholder="saturada"/>
                                                <p style={ errorMensagem }>{ errors.saturada?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="monoinsat" 
                                                label="Ácidos graxos monoinsaturados (g)">
                                                <Form.Control type="text" name="monoinsat" {...register("monoinsat")} placeholder="monoinsat"/>
                                                <p style={ errorMensagem }>{ errors.monoinsat?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="polinsat" 
                                                label="Ácidos graxos poliinsaturados (g)">
                                                <Form.Control type="text" name="polinsat" {...register("polinsat")} placeholder="polinsat"/>
                                                <p style={ errorMensagem }>{ errors.polinsat?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="omega_6" 
                                                label="Ômega 6">
                                                <Form.Control type="text" name="omega_6" {...register("omega_6")} placeholder="omega_6"/>
                                                <p style={ errorMensagem }>{ errors.omega_6?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="omega_3" 
                                                label="Ômega 3">
                                                <Form.Control type="text" name="omega_3" {...register("omega_3")} placeholder="omega_3"/>
                                                <p style={ errorMensagem }>{ errors.omega_3?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="colesterol" 
                                                label="Colesterol (mg)">
                                                <Form.Control type="text" name="colesterol" {...register("colesterol")} placeholder="colesterol"/>
                                                <p style={ errorMensagem }>{ errors.colesterol?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="cho" 
                                                label="Carboidrato (g)">
                                                <Form.Control type="text" name="cho" {...register("cho")} placeholder="cho"/>
                                                <p style={ errorMensagem }>{ errors.cho?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="fibras" 
                                                label="Fibras (g)">
                                                <Form.Control type="text" name="fibras" {...register("fibras")} placeholder="fibras"/>
                                                <p style={ errorMensagem }>{ errors.fibras?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="calcio" 
                                                label="Cálcio (mg)">
                                                <Form.Control type="text" name="calcio" {...register("calcio")} placeholder="calcio"/>
                                                <p style={ errorMensagem }>{ errors.calcio?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="mg" 
                                                label="Magnésio (mg)">
                                                <Form.Control type="text" name="mg" {...register("mg")} placeholder="mg"/>
                                                <p style={ errorMensagem }>{ errors.mg?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="mn" 
                                                label="Manganês (mg)">
                                                <Form.Control type="text" name="mn" {...register("mn")} placeholder="mn"/>
                                                <p style={ errorMensagem }>{ errors.mn?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="p" 
                                                label="Fósforo (mg)">
                                                <Form.Control type="text" name="p" {...register("p")} placeholder="p"/>
                                                <p style={ errorMensagem }>{ errors.p?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="ferro" 
                                                label="Ferro (mg)">
                                                <Form.Control type="text" name="ferro" {...register("ferro")} placeholder="ferro"/>
                                                <p style={ errorMensagem }>{ errors.ferro?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="sodio" 
                                                label="Sódio (mg)">
                                                <Form.Control type="text" name="sodio" {...register("sodio")} placeholder="sodio"/>
                                                <p style={ errorMensagem }>{ errors.sodio?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="potassio" 
                                                label="Potássio (mg)">
                                                <Form.Control type="text" name="potassio" {...register("potassio")} placeholder="potassio"/>
                                                <p style={ errorMensagem }>{ errors.potassio?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="cobre" 
                                                label="Cobre (mg)">
                                                <Form.Control type="text" name="cobre" {...register("cobre")} placeholder="cobre"/>
                                                <p style={ errorMensagem }>{ errors.cobre?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="zinco" 
                                                label="Zinco (mg)">
                                                <Form.Control type="text" name="zinco" {...register("zinco")} placeholder="zinco"/>
                                                <p style={ errorMensagem }>{ errors.zinco?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="retinol" 
                                                label="Retinol (mcg)">
                                                <Form.Control type="text" name="retinol" {...register("retinol")} placeholder="retinol"/>
                                                <p style={ errorMensagem }>{ errors.retinol?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="tiamina" 
                                                label="Tiamina (mg)">
                                                <Form.Control type="text" name="tiamina" {...register("tiamina")} placeholder="tiamina"/>
                                                <p style={ errorMensagem }>{ errors.tiamina?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="riboflavina" 
                                                label="Riboflavina (mg)">
                                                <Form.Control type="text" name="riboflavina" {...register("riboflavina")} placeholder="riboflavina"/>
                                                <p style={ errorMensagem }>{ errors.riboflavina?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="piridoxina" 
                                                label="Piridoxina (mg)">
                                                <Form.Control type="text" name="piridoxina" {...register("piridoxina")} placeholder="piridoxina"/>
                                                <p style={ errorMensagem }>{ errors.piridoxina?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="niacina" 
                                                label="Niacina (mg)">
                                                <Form.Control type="text" name="niacina" {...register("niacina")} placeholder="niacina"/>
                                                <p style={ errorMensagem }>{ errors.niacina?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="vit_c" 
                                                label="Vitamina C (mg)">
                                                <Form.Control type="text" name="vit_c" {...register("vit_c")} placeholder="vit_c"/>
                                                <p style={ errorMensagem }>{ errors.vit_c?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="equiv_niacina" 
                                                label="Equiv. Niacina">
                                                <Form.Control type="text" name="equiv_niacina" {...register("equiv_niacina")} placeholder="equiv_niacina"/>
                                                <p style={ errorMensagem }>{ errors.equiv_niacina?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="cobalamina" 
                                                label="Cobalamina (mcg)">
                                                <Form.Control type="text" name="cobalamina" {...register("cobalamina")} placeholder="cobalamina"/>
                                                <p style={ errorMensagem }>{ errors.cobalamina?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="equiv_folato" 
                                                label="Equiv. Folato">
                                                <Form.Control type="text" name="equiv_folato" {...register("equiv_folato")} placeholder="equiv_folato"/>
                                                <p style={ errorMensagem }>{ errors.equiv_folato?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="vit_d" 
                                                label="Vitamina D (mcg)">
                                                <Form.Control type="text" name="vit_d" {...register("vit_d")} placeholder="vit_d"/>
                                                <p style={ errorMensagem }>{ errors.vit_d?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="vit_e" 
                                                label="Vitamina E (mg)">
                                                <Form.Control type="text" name="vit_e" {...register("vit_e")} placeholder="vit_e"/>
                                                <p style={ errorMensagem }>{ errors.vit_e?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="trans" 
                                                label="Ácidos graxos trans (g)">
                                                <Form.Control type="text" name="trans" {...register("trans")} placeholder="trans"/>
                                                <p style={ errorMensagem }>{ errors.trans?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="sodio_adicao" 
                                                label="Sódio por Adição (g)">
                                                <Form.Control type="text" name="sodio_adicao" {...register("sodio_adicao")} placeholder="sodio_adicao"/>
                                                <p style={ errorMensagem }>{ errors.sodio_adicao?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="selenio" 
                                                label="Selênio (mcg)">
                                                <Form.Control type="text" name="selenio" {...register("selenio")} placeholder="selenio"/>
                                                <p style={ errorMensagem }>{ errors.selenio?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="vit_a" 
                                                label="Vitamina A - RAE (mcg)">
                                                <Form.Control type="text" name="vit_a" {...register("vit_a")} placeholder="vit_a"/>
                                                <p style={ errorMensagem }>{ errors.vit_a?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="acucar_total" 
                                                label="Açúcar Total (g)">
                                                <Form.Control type="text" name="acucar_total" {...register("acucar_total")} placeholder="acucar_total"/>
                                                <p style={ errorMensagem }>{ errors.acucar_total?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel 
                                                id="acucar_adicao" 
                                                label="Açúcar por Adição (g)">
                                                <Form.Control type="text" name="acucar_adicao" {...register("acucar_adicao")} placeholder="acucar_adicao"/>
                                                <p style={ errorMensagem }>{ errors.acucar_adicao?.message }</p>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Floating>
                                            <FloatingLabel label="Selecione um Grupo">
                                                <Form.Select aria-label="Selecione"  {...register("id_grupo_alimentar")}>
                                                    <option >Selecione</option>
                                                    {
                                                        optionsGruposAlimentos.map((grupo, key) => {
                                                            return (
                                                                <option key={key} value={grupo.id} >{ grupo.label }</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Form.Floating>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={{ span: 4 }}>
                                        <Button id="my-btn-primary" style={{width: '100%'}} className="btn-fill pull-right" type="submit" >
                                            <FontAwesomeIcon icon={faSave} /> Cadastrar
                                        </Button>
                                    </Col>
                                </Row>
                                <div className="clearfix"></div>
                            </Form>
                    </Card.Body>
                </Card>
            </Row>
            <br></br>
        </Container>
    );
}

export default FormAdicionarAlimento;
