// React
import { useEffect, useState, React } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from 'react-select';

// React Bootstrap
import {
    Button,
    Table,
    Row,
    Col,
    Container,
    Modal,
    Form,
    FloatingLabel,
    Card,
    Breadcrumb,
    Alert
} from 'react-bootstrap';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPlusCircle, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'

// Service
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { colAcao } from "../../styled";
import { exibeValor, somaItemReceita, calculaProporcaoReceita } from "../../functions";
import { optionsFiltro } from '../../constants';
import { errorMensagem } from "../../styled";

const validaForm = yup.object().shape({
});

function ListaAlimentoReceita(){
    const { id_receita } = useParams();
    const [receita, setReceitas] = useState([]);
    const [alimentos, setAlimentos] = useState([]);
    const [ingrediente, setIngrediente] = useState();
    const [itensReceita, setItensReceita] = useState([]);
    const [filtro, setFiltro] = useState([]);
    const [search, setSearch] = useState("");
    let history = useHistory();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validaForm)
    });

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const modalAddIngrediente = () => setShow(true);


    useEffect(() => {
        // Busca receita com base no id
        api.get(`/receita/${ id_receita }`)
        .then((response) => {
            reset(response.data.receita);
            setReceitas(response.data.receita);
        });

        // Busca todos os itens de uma receita
        api.get(`/receitaAlimentos/${ id_receita }`)
        .then(response => {
            const data = response.data.itensReceita;

            // PROCESSAMENTO PARA CONVERTER O VALOR DO BANCO NO VALOR REAL COM BASE NA QUANTIDADE PASSADA
            var dataAux = [];
            data.map(item => {
                var json = {}
                json['id_alimento'] = item.id_alimento;
                json['id_receita'] = item.id_receita;
                json['id_receita_alimento'] = item.id_receita_alimento;
                json['desc_alimento'] = item.desc_alimento;
                json['qtde'] = item.qtde;
                json['energia_kcal'] = exibeValor(item.energia_kcal, item.qtde);
                json['proteina'] = exibeValor(item.proteina, item.qtde);
                json['lipideos'] = exibeValor(item.lipideos, item.qtde);
                json['saturada'] = exibeValor(item.saturada, item.qtde);
                json['monoinsat'] = exibeValor(item.monoinsat, item.qtde);
                json['polinsat'] = exibeValor(item.polinsat, item.qtde);
                json['omega_6'] = exibeValor(item.omega_6, item.qtde);
                json['omega_3'] = exibeValor(item.omega_3, item.qtde);
                json['colesterol'] = exibeValor(item.colesterol, item.qtde);
                json['cho'] = exibeValor(item.cho, item.qtde);
                json['fibras'] = exibeValor(item.fibras, item.qtde);
                json['calcio'] = exibeValor(item.calcio, item.qtde);
                json['mg'] = exibeValor(item.mg, item.qtde);
                json['mn'] = exibeValor(item.mn, item.qtde);
                json['p'] = exibeValor(item.p, item.qtde);
                json['ferro'] = exibeValor(item.ferro, item.qtde);
                json['sodio'] = exibeValor(item.sodio, item.qtde);
                json['potassio'] = exibeValor(item.potassio, item.qtde);
                json['cobre'] = exibeValor(item.cobre, item.qtde);
                json['zinco'] = exibeValor(item.zinco, item.qtde);
                json['retinol'] = exibeValor(item.retinol, item.qtde);
                json['tiamina'] = exibeValor(item.tiamina, item.qtde);
                json['riboflavina'] = exibeValor(item.riboflavina, item.qtde);
                json['piridoxina'] = exibeValor(item.piridoxina, item.qtde);
                json['niacina'] = exibeValor(item.niacina, item.qtde);
                json['vit_c'] = exibeValor(item.vit_c, item.qtde);
                json['equiv_niacina'] = exibeValor(item.equiv_niacina, item.qtde);
                json['cobalamina'] = exibeValor(item.cobalamina, item.qtde);
                json['equiv_folato'] = exibeValor(item.equiv_folato, item.qtde);
                json['vit_d'] = exibeValor(item.vit_d, item.qtde);
                json['vit_e'] = exibeValor(item.vit_e, item.qtde);
                json['trans'] = exibeValor(item.trans, item.qtde);
                json['sodio_adicao'] = exibeValor(item.sodio_adicao, item.qtde);
                json['selenio'] = exibeValor(item.selenio, item.qtde);
                json['vit_a_rae'] = exibeValor(item.vit_a_rae, item.qtde);
                json['acucar_total'] = exibeValor(item.acucar_total, item.qtde);
                json['acucar_adicao'] = exibeValor(item.acucar_adicao, item.qtde);
                
                dataAux.push(json)
            })
            
            setItensReceita(dataAux);
        })
        .catch(error => console.log(error));

    }, []);

    useEffect(() => {
        // Busca todos os alimentos para preencher react-select
        const data = {
            filtro: filtro.map(item => item.id),
            search
        };

        api.post("/alimento", data)
        .then(response => {
            const data = response.data.alimentos;
            setAlimentos(data);
        })
        .catch(error => console.log(error));
    }, [filtro, search]);

    const salvarReceita = (data) => {
        console.log(itensReceita)
        
        var soma_qtde = parseFloat(0);
        var soma_energia_kcal = parseFloat(0);
        var soma_proteina = parseFloat(0);
        var soma_lipideos = parseFloat(0);
        var soma_saturada = parseFloat(0);
        var soma_monoinsat = parseFloat(0);
        var soma_polinsat = parseFloat(0);
        var soma_omega_6 = parseFloat(0);
        var soma_omega_3 = parseFloat(0);
        var soma_colesterol = parseFloat(0);
        var soma_cho = parseFloat(0);
        var soma_fibras = parseFloat(0);
        var soma_calcio = parseFloat(0);
        var soma_mg = parseFloat(0);
        var soma_mn = parseFloat(0);
        var soma_p = parseFloat(0);
        var soma_ferro = parseFloat(0);
        var soma_sodio = parseFloat(0);
        var soma_potassio = parseFloat(0);
        var soma_cobre = parseFloat(0);
        var soma_zinco = parseFloat(0);
        var soma_retinol = parseFloat(0);
        var soma_tiamina = parseFloat(0);
        var soma_riboflavina = parseFloat(0);
        var soma_piridoxina = parseFloat(0);
        var soma_niacina = parseFloat(0);
        var soma_vit_c = parseFloat(0);
        var soma_equiv_niacina = parseFloat(0);
        var soma_cobalamina = parseFloat(0);
        var soma_equiv_folato = parseFloat(0);
        var soma_vit_d = parseFloat(0);
        var soma_vit_e = parseFloat(0);
        var soma_trans = parseFloat(0);
        var soma_sodio_adicao = parseFloat(0);
        var soma_selenio = parseFloat(0);
        var soma_vit_a_rae = parseFloat(0);
        var soma_acucar_total = parseFloat(0);
        var soma_acucar_adicao = parseFloat(0);

        itensReceita.map((itens, key) => {
            soma_qtde = parseFloat(itens.qtde)+parseFloat(soma_qtde);
            soma_energia_kcal = somaItemReceita(itens.energia_kcal, soma_energia_kcal);
            soma_proteina = somaItemReceita(itens.proteina, soma_proteina);
            soma_lipideos = somaItemReceita(itens.lipideos, soma_lipideos);
            soma_saturada = somaItemReceita(itens.saturada, soma_saturada);
            soma_monoinsat = somaItemReceita(itens.monoinsat, soma_monoinsat);
            soma_polinsat = somaItemReceita(itens.polinsat, soma_polinsat);
            soma_omega_6 = somaItemReceita(itens.omega_6, soma_omega_6);
            soma_omega_3 = somaItemReceita(itens.omega_3, soma_omega_3);
            soma_colesterol = somaItemReceita(itens.colesterol, soma_colesterol);
            soma_cho = somaItemReceita(itens.cho, soma_cho);
            soma_fibras = somaItemReceita(itens.fibras, soma_fibras);
            soma_calcio = somaItemReceita(itens.calcio, soma_calcio);
            soma_mg = somaItemReceita(itens.mg, soma_mg);
            soma_mn = somaItemReceita(itens.mn, soma_mn);
            soma_p = somaItemReceita(itens.p, soma_p);
            soma_ferro = somaItemReceita(itens.ferro, soma_ferro);
            soma_sodio = somaItemReceita(itens.sodio, soma_sodio);
            soma_potassio = somaItemReceita(itens.potassio, soma_potassio);
            soma_cobre = somaItemReceita(itens.cobre, soma_cobre);
            soma_zinco = somaItemReceita(itens.zinco, soma_zinco);
            soma_retinol = somaItemReceita(itens.retinol, soma_retinol);
            soma_tiamina = somaItemReceita(itens.tiamina, soma_tiamina);
            soma_riboflavina = somaItemReceita(itens.riboflavina, soma_riboflavina);
            soma_piridoxina = somaItemReceita(itens.piridoxina, soma_piridoxina);
            soma_niacina = somaItemReceita(itens.niacina, soma_niacina);
            soma_vit_c = somaItemReceita(itens.vit_c, soma_vit_c);
            soma_equiv_niacina = somaItemReceita(itens.equiv_niacina, soma_equiv_niacina);
            soma_cobalamina = somaItemReceita(itens.cobalamina, soma_cobalamina);
            soma_equiv_folato = somaItemReceita(itens.equiv_folato, soma_equiv_folato);
            soma_vit_d = somaItemReceita(itens.vit_d, soma_vit_d);
            soma_vit_e = somaItemReceita(itens.vit_e, soma_vit_e);
            soma_trans = somaItemReceita(itens.trans, soma_trans);
            soma_sodio_adicao = somaItemReceita(itens.sodio_adicao, soma_sodio_adicao);
            soma_selenio = somaItemReceita(itens.selenio, soma_selenio);
            soma_vit_a_rae = somaItemReceita(itens.vit_a_rae, soma_vit_a_rae);
            soma_acucar_total = somaItemReceita(itens.acucar_total, soma_acucar_total);
            soma_acucar_adicao = somaItemReceita(itens.acucar_adicao, soma_acucar_adicao);
        })

        var jsonAlimento = {}
        jsonAlimento['desc_alimento'] = receita.nome_receita;
        jsonAlimento['qtde'] = (soma_qtde !== 0) ? calculaProporcaoReceita(soma_qtde, soma_qtde) : null;
        jsonAlimento['energia_kcal'] = (soma_energia_kcal !== 0) ? calculaProporcaoReceita(soma_energia_kcal, soma_qtde) : null;
        jsonAlimento['proteina'] = (soma_proteina !== 0) ? calculaProporcaoReceita(soma_proteina, soma_qtde) : null;
        jsonAlimento['lipideos'] = (soma_lipideos !== 0) ? calculaProporcaoReceita(soma_lipideos, soma_qtde) : null;
        jsonAlimento['saturada'] = (soma_saturada !== 0) ? calculaProporcaoReceita(soma_saturada, soma_qtde) : null;
        jsonAlimento['monoinsat'] = (soma_monoinsat !== 0) ? calculaProporcaoReceita(soma_monoinsat, soma_qtde) : null;
        jsonAlimento['polinsat'] = (soma_polinsat !== 0) ? calculaProporcaoReceita(soma_polinsat, soma_qtde) : null;
        jsonAlimento['omega_6'] = (soma_omega_6 !== 0) ? calculaProporcaoReceita(soma_omega_6, soma_qtde) : null;
        jsonAlimento['omega_3'] = (soma_omega_3 !== 0) ? calculaProporcaoReceita(soma_omega_3, soma_qtde) : null;
        jsonAlimento['colesterol'] = (soma_colesterol !== 0) ? calculaProporcaoReceita(soma_colesterol, soma_qtde) : null;
        jsonAlimento['cho'] = (soma_cho !== 0) ? calculaProporcaoReceita(soma_cho, soma_qtde) : null;
        jsonAlimento['fibras'] = (soma_fibras !== 0) ? calculaProporcaoReceita(soma_fibras, soma_qtde) : null;
        jsonAlimento['calcio'] = (soma_calcio !== 0) ? calculaProporcaoReceita(soma_calcio, soma_qtde) : null;
        jsonAlimento['mg'] = (soma_mg !== 0) ? calculaProporcaoReceita(soma_mg, soma_qtde) : null;
        jsonAlimento['mn'] = (soma_mn !== 0) ? calculaProporcaoReceita(soma_mn, soma_qtde) : null;
        jsonAlimento['p'] = (soma_p !== 0) ? calculaProporcaoReceita(soma_p, soma_qtde) : null;
        jsonAlimento['ferro'] = (soma_ferro !== 0) ? calculaProporcaoReceita(soma_ferro, soma_qtde) : null;
        jsonAlimento['sodio'] = (soma_sodio !== 0) ? calculaProporcaoReceita(soma_sodio, soma_qtde) : null;
        jsonAlimento['potassio'] = (soma_potassio !== 0) ? calculaProporcaoReceita(soma_potassio, soma_qtde) : null;
        jsonAlimento['cobre'] = (soma_cobre !== 0) ? calculaProporcaoReceita(soma_cobre, soma_qtde) : null;
        jsonAlimento['zinco'] = (soma_zinco !== 0) ? calculaProporcaoReceita(soma_zinco, soma_qtde) : null;
        jsonAlimento['retinol'] = (soma_retinol !== 0) ? calculaProporcaoReceita(soma_retinol, soma_qtde) : null;
        jsonAlimento['tiamina'] = (soma_tiamina !== 0) ? calculaProporcaoReceita(soma_tiamina, soma_qtde) : null;
        jsonAlimento['riboflavina'] = (soma_riboflavina !== 0) ? calculaProporcaoReceita(soma_riboflavina, soma_qtde) : null;
        jsonAlimento['piridoxina'] = (soma_piridoxina !== 0) ? calculaProporcaoReceita(soma_piridoxina, soma_qtde) : null;
        jsonAlimento['niacina'] = (soma_niacina !== 0) ? calculaProporcaoReceita(soma_niacina, soma_qtde) : null;
        jsonAlimento['vit_c'] = (soma_vit_c !== 0) ? calculaProporcaoReceita(soma_vit_c, soma_qtde) : null;
        jsonAlimento['equiv_niacina'] = (soma_equiv_niacina !== 0) ? calculaProporcaoReceita(soma_equiv_niacina, soma_qtde) : null;
        jsonAlimento['cobalamina'] = (soma_cobalamina !== 0) ? calculaProporcaoReceita(soma_cobalamina, soma_qtde) : null;
        jsonAlimento['equiv_folato'] = (soma_equiv_folato !== 0) ? calculaProporcaoReceita(soma_equiv_folato, soma_qtde) : null;
        jsonAlimento['vit_d'] = (soma_vit_d !== 0) ? calculaProporcaoReceita(soma_vit_d, soma_qtde) : null;
        jsonAlimento['vit_e'] = (soma_vit_e !== 0) ? calculaProporcaoReceita(soma_vit_e, soma_qtde) : null;
        jsonAlimento['trans'] = (soma_trans !== 0) ? calculaProporcaoReceita(soma_trans, soma_qtde) : null;
        jsonAlimento['sodio_adicao'] = (soma_sodio_adicao !== 0) ? calculaProporcaoReceita(soma_sodio_adicao, soma_qtde) : null;
        jsonAlimento['selenio'] = (soma_selenio !== 0) ? calculaProporcaoReceita(soma_selenio, soma_qtde) : null;
        jsonAlimento['vit_a_rae'] = (soma_vit_a_rae !== 0) ? calculaProporcaoReceita(soma_vit_a_rae, soma_qtde) : null;
        jsonAlimento['acucar_total'] = (soma_acucar_total !== 0) ? calculaProporcaoReceita(soma_acucar_total, soma_qtde) : null;
        jsonAlimento['acucar_adicao'] = (soma_acucar_adicao !== 0) ? calculaProporcaoReceita(soma_acucar_adicao, soma_qtde) : null;

        console.log("Salvar receita como alimento ", jsonAlimento)

        api.post("/alimento/cadastrar", jsonAlimento)
        .then( (res) => { 
            var msg = res.data.mensagem;
            alert(msg);
            history.push("/receitas");
        })
        .catch( (err) => { 
            var msg = err.response.data.mensagem;
            alert(msg)
        })
    };
    
    const addIngrediente = (data) => { 
        data.id_alimento = ingrediente.id_alimento;
        data.qtde = (data.qtde != "") ? parseFloat((data.qtde).replace(",", ".")) : "";
        
        api.post("/receitaAlimentos/cadastrar", data)
        .then( () => { 
            handleClose();
            document.location.reload();
        })
        .catch( (data) => {
            alert(data.response.data.mensagem)
        })
    };

    function deletaItemReceita(id){
        api.delete(`/receitaAlimentos/deletar/${id}`);
        setItensReceita(itensReceita.filter(itensReceita => itensReceita.id_receita_alimento !== id));
    };
    
    return (
        <Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Ingrediente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(addIngrediente)}> 
                        <Row>
                            <Select
                                onChange={setFiltro}
                                options={optionsFiltro} 
                                isMulti
                                placeholder="Selecione tabela de origem"
                                id="filtro"
                                name="filtro"
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.label}
                            />
                        </Row>
                        <br></br>
                        <Row>
                            <Col md={12}>
                                <Select
                                    onChange={setIngrediente}
                                    options={alimentos} 
                                    placeholder="Selecione um alimento"
                                    getOptionValue={(option) => option.id_alimento}
                                    getOptionLabel={(option) => option.desc_alimento}
                                />    
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={12}>
                                <Form.Floating>
                                    <FloatingLabel 
                                        id="qtde" 
                                        label="Quantidade (g)">
                                        <Form.Control type="text" name="qtde" {...register("qtde")} placeholder="qtde"/>
                                        <p style={ errorMensagem }>{ errors.qtde?.message }</p>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button id="my-btn-primary" className="btn-fill pull-right" type="submit" >
                                    <FontAwesomeIcon icon={faPlusCircle} /> Confirmar
                                </Button>
                            </Col>
                        </Row>
                        <div className="clearfix"></div>
                    </Form>
                </Modal.Body>
            </Modal>

            < br />
            <Row>
                <Col md={8}>
                    <h2>{ receita.nome_receita }</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/receitas">Receita</Breadcrumb.Item>
                        <Breadcrumb.Item active>Ingredientes</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={2}>
                    <Button id="my-btn-success" className="btn-fill pull-right" type="submit" onClick={modalAddIngrediente}> 
                        <FontAwesomeIcon icon={faAdd} /> Adicionar Ingrediente
                    </Button>
                </Col>
                <Col md={2}>
                    <Button id="my-btn-primary" className="btn-fill pull-right" type="submit" onClick={salvarReceita}> 
                        <FontAwesomeIcon icon={faSave} /> Salvar como Alimento
                    </Button>
                </Col>
            </Row>
            < br />
            <Card >
                <Card.Header>
                    <Card.Title>Lista de ingredientes</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text> </Card.Text>
                    {
                        (itensReceita.length >= 1) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Alimento</th>
                                        <th>Qtde Receita</th>
                                        <th>Energia (KCal)</th>
                                        <th>Carboidrato (g)</th>
                                        <th style={ colAcao }>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    itensReceita.map((itens, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{ itens.desc_alimento }</td>
                                                <td>{ itens.qtde }</td>
                                                <td>{ itens.energia_kcal }</td>
                                                <td>{ itens.cho }</td>
                                                <td style={ colAcao }>
                                                    <Button id="my-btn-danger" className="btn-fill pull-right" onClick={ () => deletaItemReceita(itens.id_receita_alimento) } type="submit" >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                            )
                                    })}
                                </tbody>
                            </Table>
                        :
                            <Alert key='warning' variant='warning'>
                                Não possui dados cadastrados
                            </Alert>
                    }
                </Card.Body>
            </Card>
            <br></br>
        </Container>
    );
}

export default ListaAlimentoReceita;