// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// React Bootstrap
import {
    Container,
    Button,
    Table,
    Row,
    Col,
    Form,
    FloatingLabel,
    Card,
    Breadcrumb,
    Alert
} from 'react-bootstrap';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileClipboard, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faPencil, faUserPlus } from '@fortawesome/free-solid-svg-icons';

// Service
import api from "../../services/api";

// Outros
import { getUsuario } from "../../config/auth";
import { btnFull, colAcao } from '../../styled'

function ListaPessoas(){
    const [pessoas, setPessoas] = useState([]);
    const [projeto, setProjeto] = useState([]);
    const [search, setSearch] = useState("");
    const id_projeto = getUsuario().id_projeto;

    function objetoRecordatorio(num_recordatorios){
        var data = [];
        for(var i = 1; i <= num_recordatorios; i++){
            const json = {};
            json['id'] = i;
            
            data.push(json)
        }
        return data;
    }

    useEffect(() => {
        const data = {
            nome: search,
            funcao: getUsuario().funcao,
            id_projeto: id_projeto
        };

        api.post("/pessoa", data, 
        ).then(response => {
            const data = response.data.pessoas;

            setPessoas(data);
        })
        .catch(error => console.log(error));

        api.get(`/projeto/${id_projeto}`, 
        ).then(response => {
            const data = response.data.projeto;

            setProjeto(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function deletaPessoa(id){
        const pessoa = pessoas.filter(pessoa => pessoa.id_pessoa === id);

        if (window.confirm('Deseja realmente excluir o indivíduo '+pessoa[0].nome+'?')) {
            api.delete(`/pessoa/deletar/${id}`);
            setPessoas(pessoas.filter(pessoa => pessoa.id_pessoa !== id));
        }
    };
    
    return (
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Pessoas</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Pessoas</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={4}>
                    <Form.Floating>
                        <FloatingLabel label="Buscar por Nome">
                            <Form.Control type="text" id="buscar_pessoa" name="buscar_pessoa" placeholder="buscar_pessoa" onChange={(e) => setSearch(e.target.value)} />
                        </FloatingLabel>
                    </Form.Floating>
                </Col>
                <Col md={2}>
                    <Link to={{ pathname: '/addPessoa' }} > 
                        <Button style={ btnFull } id="my-btn-primary" className="btn-fill pull-right" type="submit" > 
                            <FontAwesomeIcon icon={faUserPlus} /> Adicionar Pessoas
                        </Button>
                    </Link>
                </Col>
            </Row>
            < br />
            
            <Card >
                <Card.Header>
                    <Card.Title>Lista de pessoas</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text> </Card.Text>
                    {
                        (pessoas.length >= 1) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Projeto</th>
                                        <th>Identificador</th>
                                        <th>Nome</th>
                                        <th style={ colAcao }>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { 
                                        pessoas.map((pessoa, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{ pessoa.nome_projeto }</td>
                                                    <td>{ pessoa.identificador }</td>
                                                    <td>{ pessoa.nome }</td>
                                                    <td style={ colAcao }>
                                                        {
                                                            objetoRecordatorio(pessoa.num_recordatorio).map(index => {
                                                                return ( 
                                                                    <Link to={{ pathname: `/recordatorios/${pessoa.id_pessoa}/${index.id}` }} > 
                                                                        <Button id={`my-btn-primary-${index.id}`} className="btn-fill pull-right" type="submit" title={`Recordatório do dia ${index.id}`} > 
                                                                            <FontAwesomeIcon icon={faFileClipboard} />
                                                                        </Button>
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                        <Link to={{ pathname: `/edtPessoa/${pessoa.id_pessoa}` }} > 
                                                            <Button id="my-btn-warning" className="btn-fill pull-right" type="submit" title="Editar pessoa" > 
                                                                <FontAwesomeIcon icon={faPencil} />
                                                            </Button>
                                                        </Link>
                                                        <Button  id="my-btn-danger" className="btn-fill pull-right" onClick={ () => deletaPessoa(pessoa.id_pessoa) } type="submit" title="Deletar pessoa" >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                                )
                                        }) 
                                    } 
                                </tbody>
                            </Table>
                        :
                            <Alert key='warning' variant='warning'>
                                Não possui dados cadastrados
                            </Alert>
                    }
                </Card.Body>
            </Card>
            <br></br>
        </Container>
    );
}

export default ListaPessoas;