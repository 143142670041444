// React
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom"

// React Bootstrap
import {
    Button,
    Form,
    Col,
    Row,
    FloatingLabel
} from 'react-bootstrap';

// Service
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuthStore } from "../../config/store";
import { errorMensagem } from "../../styled";

import logo from '../../assets/img/logo2.png'

const validaForm = yup.object().shape({
    email: yup.string().required("Favor informe seu email"),
    senha: yup.string().required('Favor informe sua senha')
});

function Login(){
    let testeLogin = "Login";
    let history = useHistory();
    let setJwt = useAuthStore((state) => state.setJwt);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });

    const login = (data) => {
        console.log(data)
        api.post('/login', data)
        .then( (response) => {
            localStorage.setItem("tokenAPI", response.data.token);
            setJwt(response.data.token);
            
            history.push("/pessoas");
        })
        .catch( (err) => { 
            var msg = err.response.data.mensagem;
            alert(msg)
        })
    
    }

    return(
        <>
            <br />
            <br />
            <br />
            <Col md={{ span: 4, offset: 4 }} >
                <Row>
                    <Col >
                        <Form onSubmit={handleSubmit(login)}> 
                            <Form.Control type="hidden" value={testeLogin} id="telaLogin"/>
                            <Row>
                                <Col md={{ span: 4, offset: 2 }} >
                                    <img src={logo} alt="Alguma coisa" height={150}/>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col md={12}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            controlId="email" 
                                            label="E-mail">
                                            <Form.Control type="text" name="email" {...register("email")} placeholder="email"/>
                                            <p style={ errorMensagem }>{ errors.email?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            controlId="senha" 
                                            label="Senha">
                                            <Form.Control type="password" name="senha" {...register("senha")} placeholder="senha"/>
                                            <p style={ errorMensagem }>{ errors.senha?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} >
                                    <Button id="my-btn-primary" style={{width: '100%'}} className="btn-fill pull-right" type="submit" >
                                        Login
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} >
                                    <Link to={{ pathname: '/esqueciSenha' }} > 
                                        <a > 
                                            Esqueci minha senha
                                        </a>
                                    </Link>
                                </Col>
                            </Row>
                            {/*
                            */}
                        </Form>
                    </Col>
                </Row>
            </Col>
        </>
    );
}

export default Login;
