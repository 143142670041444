import { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Table,
    Accordion
} from "react-bootstrap";

import { optionsFiltro, optionsLocal, optionsRefeicao, optionsGruposAlimentos } from '../../constants'

function ListaFaq () {
    return (
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>FAQ</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Row>
                <p>Referência/legenda para análise dos relatórios gerados.</p>
            </Row>
            <Row>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Grupo Alimimentar</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Valor</th>
                                        <th>Referência</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log("TESTE ", optionsGruposAlimentos)}
                                    {
                                        optionsGruposAlimentos.map(item => {
                                            return (
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td>{item.label}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Refeição</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Valor</th>
                                        <th>Referência</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        optionsRefeicao.map( (item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{item.id}</td>
                                                    <td>{item.label}</td>
                                                </tr>
                                            )
                                        }) 
                                    }
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Local</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Valor</th>
                                        <th>Referência</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        optionsLocal.map( (item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{item.id}</td>
                                                    <td>{item.label}</td>
                                                </tr>
                                            )
                                        }) 
                                    }
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
        </Container>
    );
}

export default ListaFaq;