// React
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { useForm } from "react-hook-form";

// React Bootstrap
import {
    Button,
    Table,
    Row,
    Col,
    Container,
    Form,
    Card,
    Breadcrumb
} from 'react-bootstrap';

// Icone
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

// Componentes
import AlimentosPag from './Alimento';
import Pagination from './Pagination';

// Services
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { btnFull, colAcao, textFiltro } from '../../styled';
import { optionsFiltro } from '../../constants';

const validaForm = yup.object().shape({
    identificador: yup.number().typeError('Favor informe o identificador da pessoa').required(),
    nome: yup.string().required("Favor informe o nome do pessoa"),
});

function ListaAlimentos(){
    const [alimentos, setAlimento] = useState([]);
    const [filtro, setFiltro] = useState([]);
    const [search, setSearch] = useState("");
    
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [alimentosPorPag] = useState(300);

    const paginate = pageNumber => setCurrentPage(pageNumber);


    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });

    useEffect(() => {

        const data = {
            filtro: filtro.map(item => item.id),
            search
        };

        const getAlimentos = () => {
            setLoading(true);
            api.post("/alimento", data).then(
                response => {
                    const data = response.data.alimentos;
                    setAlimento(data);
                    setLoading(false);
                }
            ).catch(error => console.log(error))
        };

        getAlimentos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtro, search]);

    const indexOfLastAlimentosPag = currentPage * alimentosPorPag;
    const indexOfFirstAlimentosPag = indexOfLastAlimentosPag - alimentosPorPag;
    const currentAlimentosPag = alimentos.slice(indexOfFirstAlimentosPag, indexOfLastAlimentosPag);

    return (
        <Container>
            <br />
            <Row>
                <Col md={10}>
                    <h2>Alimentos</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Alimentos</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={2}>
                    <Link to="/addAlimento" > 
                        <Button style={ btnFull } id="my-btn-primary" className="btn-fill pull-right" type="submit" > 
                            <FontAwesomeIcon icon={faPlus} /> Cadastar Alimento
                        </Button>
                    </Link>
                </Col>
            </Row>
            <br />

            <Card >
                <Card.Body>
                    <Card.Title>Filtros</Card.Title>
                    <Row>
                        <Col md={2}>
                            <p  style={ textFiltro }>Buscar por nome:</p>
                        </Col>
                        <Col md={{ span: 10, offset: 0 }}>
                            <Form.Control type="text" id="buscar_alimento" name="buscar_alimento" placeholder="" onChange={(e) => setSearch(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <p style={ textFiltro }>Selecione um filtro:</p>
                        </Col>
                        <Col md={{ span: 10, offset: 0 }}>
                            <Select
                                onChange={setFiltro}
                                options={optionsFiltro} 
                                isMulti
                                placeholder=""
                                id="filtro"
                                name="filtro"
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                //onBlur={buscarPorTabelaOrigem}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
            <Card >
                <Card.Header>
                    <Card.Title>Lista de alimentos</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text> Lista de todos os alimentos com base nos filtros aplicados.</Card.Text>

                    <Pagination alimentosPorPage={alimentosPorPag} totalAlimentos={alimentos.length} paginate={paginate}/>

                    <Table hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Origem</th>
                                <th>Descrição do Alimento</th>
                                <th style={ colAcao }>Energia KCal</th>
                                <th style={ colAcao }>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <AlimentosPag alimentosPag={currentAlimentosPag} loading={loading} />
                        </tbody>
                    </Table>
                    <br></br>
                    <Pagination alimentosPorPage={alimentosPorPag} totalAlimentos={alimentos.length} paginate={paginate}/>
                </Card.Body>
            </Card>
            <br></br>
        </Container>
    );
}

export default ListaAlimentos;