import axios from "axios";
import { logout } from "../config/auth";

const api = axios.create({
    baseURL: "https://us-central1-dietbase-11e29.cloudfunctions.net/app"
    //baseURL: "http://localhost:5000/dietbase-11e29/us-central1/app"
})

/*
*/
api.interceptors.request.use(
    (config) => {
        config.headers['Authorization'] =  `token ${localStorage.getItem('tokenAPI')}`

        return config;
    },
    error => Promise.reject(error)
)

api.interceptors.response.use(
    (response) => {
        if (`${response.status}` === '401') {
            alert("Sessão expirada. Favor realizar o login novamente 1")
            logout();
        }

        return response;
    },
    error => {
        if (`${error.response.status}` === '401') {
            alert("Sessão expirada. Favor realizar o login novamente")
            logout();
        }

        throw error;
    }
)

export default api;