import { useEffect, useState, React } from "react";

// React Bootstrap
import {
    Button,
    Row,
    Col,
    Container,
    Breadcrumb,
    Card,
    Alert
} from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCutlery, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

// Services
import api from "../../services/api";

// Outros
import { useForm } from "react-hook-form";
import { btnFull, lineV } from "../../styled";
import { exibeValor } from "../../functions";
import { optionsRefeicao } from '../../constants';

function ListaRecordatorios(){
    const { id_pessoa, dia } = useParams([]);
    const [recordatorios, setRecordatorios] = useState([]);
    const [alimentos, setAlimentos] = useState([]);
    const [pessoa, setPessoa] = useState([]);
    const [filtro, setFiltro] = useState([]);
    const [search, setSearch] = useState("");
    const [refeicoes, setRefeicoes] = useState([]);

    const { formState: { errors } } = useForm({});
    
    useEffect(() => {
        api.get(`/recordatorio/${id_pessoa}/${dia}`)
        .then(response => {
            const data = response.data.recordatorios;
            setRecordatorios(data);

            var arrayRefeicoes = [];
            var arrayRefeicoesAux = [];

            data.map(item => {

                var objRefeicao = {};
                objRefeicao.id_refeicao = item.refeicao;
                objRefeicao.horario = item.horario;

                if (!arrayRefeicoesAux.includes(item.refeicao)){
                    arrayRefeicoesAux.push(item.refeicao)
                    arrayRefeicoes.push(objRefeicao)
                }
            })
            setRefeicoes(arrayRefeicoes)
        })
        .catch(error => console.log(error));

        api.get(`/pessoa/${id_pessoa}`)
        .then(response => {
            const data = response.data.pessoa;
            setPessoa(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Busca todos os alimentos para preencher react-select
        const data = {
            filtro: filtro.map(item => item.value),
            search
        }

        api.post("/alimento", data)
        .then(response => {
            const data = response.data.alimentos;
            setAlimentos(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtro, search]);

    const deletaRecordatorio = (id_refeicao) => {
        api.delete(`/recordatorio/deletar/${id_pessoa}/${dia}/${id_refeicao}`)
        .then((res) => {
            var msg = res.data.mensagem;
            alert(msg);
            document.location.reload();
        });
    }

    return (
        <Container>
            < br />
            <Row>
                <Col md={10}>
                    <h2>Recordatórios de {pessoa.nome}</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/pessoas">Pessoas</Breadcrumb.Item>
                        <Breadcrumb.Item active>Dia {dia}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={2}>
                    <Link to={{ pathname: `/recordatorios/${id_pessoa}/${dia}/refeicao` }} > 
                        <Button style={ btnFull } id={`my-btn-primary`} className="btn-fill pull-right" type="submit" title={'Adicionar Refeição'} > 
                            <FontAwesomeIcon icon={faCutlery} /> Adicionar Refeição
                        </Button>
                    </Link>
                </Col>
            </Row>

            < br />
            <Row>
                <Card >
                    <Card.Body>
                        {
                            (recordatorios.length >= 1) ? 
                                refeicoes.map((subItem, subKey) => {
                                    return (
                                        <>
                                            <Row key={subKey}>
                                                <Col md={3} style={lineV}>
                                                    <h5>
                                                        {
                                                            optionsRefeicao.map(item => (
                                                                (subItem.id_refeicao == item.id) ? item.label : ''
                                                            ))
                                                        }
                                                    </h5>
                                                    <p>{subItem.horario}</p>
                                                    <Link to={{ pathname: `/recordatorios/${id_pessoa}/${dia}/${subItem.id_refeicao}` }} > 
                                                        <Button id={`my-btn-warning`} className="btn-fill pull-right" type="submit" title={'Editar Refeição'} > 
                                                            <FontAwesomeIcon icon={faPencilAlt} /> 
                                                        </Button>
                                                    </Link>
                                                    <Button id={`my-btn-danger`} className="btn-fill pull-right" title={'Deletar Refeição'} onClick={() => deletaRecordatorio(subItem.id_refeicao)}> 
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </Col>
                                                <Col md={9} style={{ paddingLeft: '35px'}}>
                                                    {
                                                        recordatorios.map((item, key) => (
                                                            (item.refeicao === subItem.id_refeicao) ?
                                                                    <div key={key}>
                                                                        { item.desc_alimento+" - "+( (/\./.test(item.qtde)) ? (item.qtde).toString().replace(".", ",") : item.qtde )+" (g) "+((item.energia_kcal !== null) ? (" - "+exibeValor(item.energia_kcal, item.qtde)+" (Kcal/g)").replace('.', ',') : '') }
                                                                        <br></br>
                                                                    </div>
                                                            :
                                                                ""
                                                        ))
                                                    }
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                        </>
                                    )
                                })
                            :
                                <Row>
                                    <Col colSpan={7}>
                                        <Alert key='warning' variant='warning'>
                                            Não possui dados cadastrados
                                        </Alert>
                                    </Col>
                                </Row>
                        }
                    </Card.Body>
                </Card>
            </Row>
            <br></br>
        </Container>
    );
}

export default ListaRecordatorios;