export const btnFull = {
    width: '100%',
    height: 'calc(3.5rem + 2px)'
};

export const lineV = {
    textAlign: 'right',
    borderRight: 'solid #5e9551',
    paddingRight: '35px'
};

export const textFiltro = {
    marginTop: '6px'
}

export const colAcao = {
    textAlign: 'right'
}

export const errorMensagem = {
    margin: '10px 0px 20px 0px',  
    color: '#FF0000'
};