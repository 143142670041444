export const optionsLocal = [
    {
        id: 1, 
        label: 'Casa' 
    },
    {
        id: 2, 
        label: 'Escola' 
    },
    {
        id: 3, 
        label: 'Aniversário' 
    },
    {
        id: 4, 
        label: 'Carro' 
    },
    {
        id: 5, 
        label: 'Casa de outra pessoa' 
    },
    {
        id: 6, 
        label: 'Evento Social' 
    },
    {
        id: 7, 
        label: 'Festa' 
    },
    {
        id: 8, 
        label: 'Lanchonete' 
    },
    {
        id: 9, 
        label: 'Padaria' 
    },
    {
        id: 10, 
        label: 'Praça' 
    },
    {
        id: 11, 
        label: 'Refeitório Comunitário' 
    },
    {
        id: 12, 
        label: 'Restaurante' 
    },
    {
        id: 13, 
        label: 'Rua' 
    },
    {
        id: 14, 
        label: 'Trabalho' 
    },
    {
        id: 15, 
        label: 'Universidade'
     }
];

export const optionsFiltro = [
    {
        id: 'TBCA', 
        label: 'TBCA' 
    },
    {
        id: 'Outros', 
        label: 'Outros' 
    },
    {
        id: 'IBGE_2011', 
        label: 'IBGE 2011' 
    },
    {
        id: 'TACO_2011', 
        label: 'TACO 2011' 
    }
];

export const optionsRefeicao = [
    {
        id: 1, 
        label: 'Café da manhã' 
    },
    {
        id: 2, 
        label: 'Lanche da manhã' 
    },
    {
        id: 3, 
        label: 'Almoço' 
    },
    {
        id: 4, 
        label: 'Lanche da tarde' 
    },
    {
        id: 5, 
        label: 'Janta' 
    },
    {
        id: 6, 
        label: 'Ceia' 
    }
];

export const composicaoAlimentar = [
    { 
        id: 'tb_origem', 
        label: 'Tab. Origem'
    },
    { 
        id: 'desc_alimento', 
        label: 'Desc. Alimento'
    },
    { 
        id: 'qtde', 
        label: 'Quantidade (g)'
    },
    { 
        id: 'energia_kcal', 
        label: 'Energia (kcal)'
    },
    { 
        id: 'proteina', 
        label: 'Proteína (g)'
    },
    { 
        id: 'lipideos', 
        label: 'Lipídeos (g)'
    },
    { 
        id: 'saturada', 
        label: 'Ácidos graxos saturados (g)'
    },
    { 
        id: 'monoinsat', 
        label: 'Ácidos graxos monoinsaturados (g)'
    },
    { 
        id: 'polinsat', 
        label: 'Ácidos graxos poliinsaturados (g)'
    },
    { 
        id: 'omega_6', 
        label: 'Ômega 6'
    },
    { 
        id: 'omega_3', 
        label: 'Ômega 3'
    },
    { 
        id: 'colesterol', 
        label: 'Colesterol (mg)'
    },
    { 
        id: 'cho', 
        label: 'Carboidrato (g)'
    },
    { 
        id: 'fibras', 
        label: 'Fibras (g)'
    },
    { 
        id: 'calcio', 
        label: 'Cálcio (mg)'
    },
    { 
        id: 'mg', 
        label: 'Magnésio (mg)'
    },
    { 
        id: 'mn', 
        label: 'Manganês (mg)'
    },
    { 
        id: 'p', 
        label: 'Fósforo (mg)'
    },
    { 
        id: 'ferro', 
        label: 'Ferro (mg)'
    },
    { 
        id: 'sodio', 
        label: 'Sódio (mg)'
    },
    { 
        id: 'potassio', 
        label: 'Potássio (mg)'
    },
    { 
        id: 'cobre', 
        label: 'Cobre (mg)'
    },
    { 
        id: 'zinco', 
        label: 'Zinco (mg)'
    },
    { 
        id: 'retinol', 
        label: 'Retinol (mcg)'
    },
    { 
        id: 'tiamina', 
        label: 'Tiamina (mg)'
    },
    { 
        id: 'riboflavina', 
        label: 'Riboflavina (mg)'
    },
    { 
        id: 'piridoxina', 
        label: 'Piridoxina (mg)'
    },
    { 
        id: 'niacina', 
        label: 'Niacina (mg)'
    },
    { 
        id: 'vit_c', 
        label: 'Vitamina C (mg)'
    },
    { 
        id: 'equiv_niacina', 
        label: 'Equiv. Niacina'
    },
    { 
        id: 'cobalamina', 
        label: 'Cobalamina (mcg)'
    },
    { 
        id: 'equiv_folato', 
        label: 'Equiv. Folato'
    },
    { 
        id: 'vit_d', 
        label: 'Vitamina D (mcg)'
    },
    { 
        id: 'vit_e', 
        label: 'Vitamina E (mg)'
    },
    { 
        id: 'trans', 
        label: 'Ácidos graxos trans (g)'
    },
    { 
        id: 'sodio_adicao', 
        label: 'Sódio por Adição (g)'
    },
    { 
        id: 'selenio', 
        label: 'Selênio (mcg)'
    },
    { 
        id: 'vit_a_rae', 
        label: 'Vitamina A - RAE (mcg)'
    },
    { 
        id: 'acucar_total', 
        label: 'Açúcar Total (g)'
    },
    { 
        id: 'acucar_adicao', 
        label: 'Açúcar por Adição (g)'
    },
    { 
        id: 'grupo_alimentar', 
        label: 'Grupo Alimentar'
    }
];

export const optionsGruposAlimentos = [
    { 
        id: 1, 
        label: 'Alimentos in natura e minimamente processados'
    },
    { 
        id: 2, 
        label: 'Alimentos Processados'
    },
    { 
        id: 3, 
        label: 'Alimentos Ultraprocessados'
    },
    { 
        id: 4, 
        label: 'Ingredientes culinários'
    },
    { 
        id: 5, 
        label: 'Alimentos incertos'
    }
];
