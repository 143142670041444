// React
import React from "react";
import { Link } from "react-router-dom";

// React Bootstrap
import { Alert, Button } from "react-bootstrap";

// Icone
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Outros
import { colAcao } from "../../styled";


const AlimentosPag = ({ alimentosPag, loading }) => {
    function deletaAlimento(id){
        const proj = alimentosPag.filter(projeto => projeto.id_alimento === id);
    
        if (window.confirm('Deseja realmente excluir o alimento '+proj[0].desc_alimento+'?')) {
            /*
            api.delete(`/alimento/deletar/${id}`);
            */
           document.location.reload();
        }
    };

    if (loading) {
        return (
            <tr>
                <td colSpan={5}>
                    <Alert key='warning' variant='warning'>
                        Carregando...
                    </Alert>
                </td>
            </tr>
        );
    }

    return (
        <>
            {
                alimentosPag.map((alimento, key) => {
                    return (
                        <tr key={key}>
                            <td>{ alimento.id_alimento }</td>
                            <td>{ alimento.tb_origem }</td>
                            <td>{ alimento.desc_alimento }</td>
                            <td style={ colAcao }>{ (alimento.energia_kcal+'').replace('.', ',') }</td>
                            <td style={ colAcao }>
                                <Link to={{ pathname: `/edtAlimento/${alimento.id_alimento}` }} > 
                                    <Button id="my-btn-warning" className="btn-fill pull-right" type="submit" > 
                                        <FontAwesomeIcon icon={faPencil} />
                                    </Button>
                                </Link>
                                <Button id="my-btn-danger" className="btn-fill pull-right" onClick={ async () => deletaAlimento(alimento.id_alimento) }  type="submit" >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
};

export default AlimentosPag