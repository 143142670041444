export function exibeValor(origem, qtde){
    return (((origem*qtde)/100).toFixed(3)).replace('.', ',')
}

export function somaItemReceita (valor_alimento, valor_atual){
    return (
        parseFloat((valor_alimento).replace(',', '.'))+valor_atual
    )
}

export function calculaProporcaoReceita (soma_item, soma_qtde){
    const result = (soma_item * 100) / soma_qtde
    return result.toFixed(3)
}
