import { AccessControl } from "accesscontrol";
import jwtDecode from "jwt-decode";


export function logout() {
    window.location.href = window.location.origin;
    localStorage.clear();
}

export function getUsuario(){
    let tokenAPI = localStorage.getItem('tokenAPI');
    let usuario = jwtDecode(tokenAPI);
    
    return usuario;
}

export function ac(){
    const acesso = new AccessControl();
    try {        
        let tokenAPI = localStorage.getItem('tokenAPI');
        let decode = jwtDecode(tokenAPI);

        acesso.setGrants(decode.roles)
        
        return acesso.can(decode.funcao);
    } catch (error) {
        acesso.grant('deslogado');
        return acesso.can('deslogado');
    }
}

ac();
/*
export default {
    logout
};
*/