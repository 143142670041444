// React
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

// React Bootstrap
import {
    Button,
    Form,
    Col,
    Row,
    Container,
    Card,
    FloatingLabel,
    Breadcrumb
} from 'react-bootstrap';

// Icone
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

// Service
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { btnFull, errorMensagem } from '../../styled';

const validaForm = yup.object().shape({
    email: yup.string().required("Favor informe seu email")
});

function EsqueciSenha(){
    let history = useHistory();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });

    const esqueciMinhaSenha = (data) => {
        api.post('/login/esqueciMinhaSenha', data)
        .then( (res) => {
            var msg = res.data.mensagem;
            alert(msg)
            history.push("/");
        })
        .catch( (err) => { 
            var msg = err.response.data.mensagem;
            alert(msg)
        })
    }

    return(
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Recuperar senha</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/" >Voltar para tela de Login</Breadcrumb.Item>
                        
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Card >
                {/*
                <Card.Header>
                    <Card.Title>Recuperar senha</Card.Title>
                </Card.Header>
                */}
                <Card.Body>
                    <Card.Text>Por favor informe seu email cadastrado no sistema!</Card.Text>
                        <Form onSubmit={handleSubmit(esqueciMinhaSenha)}> 
                            <Row>
                                <Col md={10}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="email" 
                                            label="Email">
                                            <Form.Control type="email" name="email" {...register("email")} placeholder="email"/>
                                            <p style={ errorMensagem }>{ errors.email?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                                <Col md={2}>
                                    <Button id="my-btn-primary" style={ btnFull } className="btn-fill pull-right" type="submit" >
                                        <FontAwesomeIcon icon={faArrowsRotate} /> Recuperar
                                    </Button>
                                </Col>
                            </Row>
                            <div className="clearfix"></div>
                        </Form>
                </Card.Body>
            </Card>
        </Container>
    )
};

export default EsqueciSenha;