// React
import { useEffect, useState } from "react";

// React Bootstrap
import {
    Button,
    Table,
    Row,
    Col,
    Container,
    Form,
    FloatingLabel,
    Card,
    Breadcrumb,
    Alert 
} from 'react-bootstrap';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'

// Service
import api from "../../services/api";

// Outros
import { Link } from "react-router-dom";
import { btnFull, colAcao } from '../../styled'

function ListaProjetos(){
    const [projetos, setProjetos] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        const data = {
            nome: search
        };

        api.post("/projeto", data)
        .then(response => {
            const data = response.data.projetos;

            setProjetos(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function deletaProjeto(id){
        const proj = projetos.filter(projeto => projeto.id_projeto === id);

        if (window.confirm('Deseja realmente excluir o projeto '+proj[0].nome+'?')) {
            api.delete(`/projeto/deletar/${id}`);
            setProjetos(projetos.filter(projeto => projeto.id_projeto !== id));    
        }
    };

    return (
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Projetos</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Projetos</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={4}>
                    <Form.Floating>
                        <FloatingLabel label="Buscar por nome">
                            <Form.Control type="text" id="buscar_projeto" name="buscar_projeto" placeholder="buscar_projeto" onChange={(e) => setSearch(e.target.value)} />
                        </FloatingLabel>
                    </Form.Floating>
                </Col>
                <Col md={2}>
                    <Link to="/addProjeto" > 
                        <Button id="my-btn-primary" style={btnFull} className="btn-fill pull-right" type="submit" > 
                            <FontAwesomeIcon icon={faPlus} /> Adicionar Projetos
                        </Button>
                    </Link>
                </Col>
            </Row>
            < br />
            <Card >
                <Card.Header>
                    <Card.Title>Lista de projetos</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text> </Card.Text>
                    {
                        (projetos.length >= 1) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nome</th>
                                        <th>Descrição</th>
                                        <th>Núm. Recordatórios</th>
                                        <th style={ colAcao }>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projetos.map((projeto, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{ projeto.id_projeto }</td>
                                                <td>{ projeto.nome }</td>
                                                <td>{ projeto.descricao }</td>
                                                <td>{ projeto.num_recordatorios }</td>
                                                <td style={ colAcao }>
                                                    <Link to={{ pathname: `/edtProjeto/${projeto.id_projeto}` }} > 
                                                        <Button id="my-btn-warning" className="btn-fill pull-right" type="submit" > 
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </Button>
                                                    </Link>
                                                    <Button id="my-btn-danger" className="btn-fill pull-right" onClick={ () => deletaProjeto(projeto.id_projeto) } type="submit" >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                            )
                                    })}
                                </tbody>
                            </Table>
                        :
                            <Alert key='warning' variant='warning'>
                                Não possui dados cadastrados
                            </Alert>
                    }
                </Card.Body>
            </Card>
            <br></br>
        </Container>
    );
}

export default ListaProjetos;