import { useEffect, useState, React } from "react";

// React Bootstrap
import {
    Button,
    Table,
    Row,
    Col,
    Container,
    Form,
    FloatingLabel,
    Breadcrumb,
    Card,
    Alert
} from 'react-bootstrap';
import { useParams } from "react-router-dom";

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faTrash, faSave  } from '@fortawesome/free-solid-svg-icons'

import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from 'react-select';

// Services
import api from "../../services/api";

import { btnFull, errorMensagem } from "../../styled";
import { exibeValor } from "../../functions";
import { optionsLocal, optionsFiltro } from "../../constants";

const validaForm = yup.object().shape({
});

function FormEditarRecordatorioRefeicao(){
    
    let history = useHistory();
    const { id_pessoa, dia, refeicao } = useParams([]);
    const [localData, setLocal] = useState("");
    const [refeicaoData, setRefeicao] = useState("");
    const [horarioData, setHorario] = useState("");
    const [filtro, setFiltro] = useState([]);
    const [search, setSearch] = useState("");
    const [alimentos, setAlimentos] = useState([]);
    const [ingrediente, setIngrediente] = useState();
    const [listaIngredientes, setListaIngredientes] = useState([]);
    const [refeicaoBD, setRefeicaoBD] = useState([]);
    const [teste, setTeste] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });

    useEffect(() => {
        // Busca todos os alimentos para preencher react-select
        const data = {
            filtro: filtro.map(item => item.id),
            search
        }

        api.post("/alimento", data)
        .then(response => {
            const data = response.data.alimentos;
            setAlimentos(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtro, search]);

    useEffect(() => {
        // Busca todas as refeições cadastradas no banco de dados
        api.get("/refeicao/list")
        .then(response => {
            const data = response.data.refeicoes;
            setRefeicaoBD(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * CRIAR ENDPOINT QUE TRAGA TODOS OS ALIMENTOS CONSUMIDOS PELO INDIVIDUO NO DIA E NA REFEIÇÃO SELECIONADA
     */
    useEffect(() => {
        // Busca todos os alimentos para preencher react-select
        const data = {
            
        }

        api.post(`/recordatorio/${id_pessoa}/${dia}/${refeicao}`, data)
        .then(response => {
            const data = response.data.recordatorios;
            
            setListaIngredientes(data);
            setLocal(data[0].local);
            setRefeicao(data[0].refeicao);
            setHorario(data[0].horario);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addAlimentoRefeicao = (data) => { 
        var alimentoCompleto = '';

        api.get(`/alimento/${ingrediente.id_alimento}`)
        .then(response => {
            alimentoCompleto = response.data.alimento;

            alimentoCompleto.id_pessoa = id_pessoa;
            alimentoCompleto.dia = dia;
            alimentoCompleto.qtde = parseFloat((data.qtde).replace(",", "."));
            alimentoCompleto.local = listaIngredientes[0].local;
            alimentoCompleto.horario = listaIngredientes[0].horario;
            alimentoCompleto.refeicao = listaIngredientes[0].refeicao;

            setListaIngredientes([...listaIngredientes, alimentoCompleto])    
        })
        .catch(error => console.log(error));
    };

    const atualizar = () => {
        listaIngredientes.forEach(item => {
            item.local = localData;
            item.horario = horarioData;
            item.refeicao = refeicaoData;
        });
        
        api.patch("/recordatorio/editar", listaIngredientes)
        .then( (res) => { 
            var msg = res.data.mensagem;
            alert(msg);
            history.push(`/recordatorios/${id_pessoa}/${dia}`);
        })
        .catch( (error) => { 
            alert("Contate o administrador do sistema!")
        })
    };
    
    const deletaAlimentoRefeicao = (data) => {
        var listaIngredientesAux = [];

        listaIngredientes.map(item => {
            if (item.id_alimento !== data) {
                listaIngredientesAux.push(item)
            }
        });
        setListaIngredientes(listaIngredientesAux);
    }

    return (
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Refeição</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/pessoas">Pessoas</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/recordatorios/${id_pessoa}/${dia}`}>Dia {dia}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Row>
                <Card >
                    <Card.Body>
                        <Card.Text>Formulário para atualizar informações sobre a refeição realizada pelo indivíduo.</Card.Text>
                        <Form onSubmit={handleSubmit(addAlimentoRefeicao)}> 
                            <Row>
                                <Col md={3}>
                                    <Form.Floating >
                                        <FloatingLabel label="Selecione uma Refeição">
                                            <Form.Select aria-label="Selecione" {...register("refeicao")} onChange={(e) => setRefeicao(e.target.value)} value={refeicaoData} disabled>
                                                {
                                                    refeicaoBD.map((item, key) => {
                                                        return (
                                                            <option key={key} value={item.id}>{ item.desc_refeicao }</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating>
                                        <FloatingLabel label="Selecione um Local">
                                            <Form.Select aria-label="Selecione"  {...register("local")} onChange={(e) => setLocal(e.target.value)} value={localData}>
                                                {
                                                    optionsLocal.map((item, key) => {
                                                        return (
                                                            <option key={key} value={item.id}>{ item.label }</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                                <Col md={3}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="horario" 
                                            label="Horário">
                                            <Form.Control type="time" name="horario" {...register("horario")} onChange={(e) => setHorario(e.target.value)} value={horarioData} placeholder="horario"/>
                                            <p style={ errorMensagem }>{ errors.horario?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md={5}>
                                    <Select
                                        onChange={setFiltro}
                                        options={optionsFiltro} 
                                        isMulti
                                        placeholder="Selecione tabela de origem"
                                        id="filtro"
                                        name="filtro"
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.label}
                                    />
                                </Col>
                                <Col md={7}>
                                    <Select
                                        onChange={setIngrediente}
                                        options={alimentos} 
                                        placeholder="Selecione um alimento"
                                        getOptionValue={(option) => option.id_alimento}
                                        getOptionLabel={(option) => option.desc_alimento}
                                    />
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={4}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="qtde" 
                                            label="Quantidade (g)">
                                            <Form.Control type="text" name="qtde" {...register("qtde")} placeholder="qtde" />
                                            <p style={ errorMensagem }>{ errors.qtde?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                                <Col md={{span: 3, offset: 2}}>
                                    <Button id="my-btn-success" style={ btnFull } className="btn-fill pull-right" type="submit" >
                                        <FontAwesomeIcon icon={faAdd } /> Adicionar Alimento
                                    </Button>
                                </Col>
                                <Col md={3}>
                                    <Button id="my-btn-primary" style={ btnFull } className="btn-fill pull-right" onClick={ () => atualizar() } >
                                        <FontAwesomeIcon icon={faSave} /> Atualizar Refeição
                                    </Button>
                                </Col>
                            </Row>
                            <div className="clearfix"></div>
                        </Form>
                     
                     <hr></hr>

                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Alimento</th>
                                    <th>Quantidade (g)</th>
                                    <th>Energia (KCal)</th>
                                    <th>Proteina (g)</th>
                                    <th>Lipídeos (g)</th>
                                    <th>Carboidrato (g)</th>
                                    <th>Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (listaIngredientes.length >= 1) ?  
                                        listaIngredientes.map((item, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{ item.desc_alimento }</td>
                                                    <td>{ (item.qtde).toString().replace(".", ",") /* item.qtde */ }</td>
                                                    <td>{ (item.energia_kcal !== null) ? exibeValor(item.energia_kcal, item.qtde) : '' }</td>
                                                    <td>{ (item.proteina !== null) ? exibeValor(item.proteina, item.qtde) : '' }</td>
                                                    <td>{ (item.lipideos !== null) ? exibeValor(item.lipideos, item.qtde) : '' }</td>
                                                    <td>{ (item.cho !== null) ? exibeValor(item.cho, item.qtde) : '' }</td>
                                                    <td>
                                                        <Button className="btn-fill pull-right" onClick={ () => deletaAlimentoRefeicao(item.id_alimento) } type="submit" variant="danger" >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                                )
                                        })
                                    :
                                        <tr>
                                            <td colSpan={7}>
                                                <Alert key='warning' variant='warning'>
                                                    Não possui dados cadastrados
                                                </Alert>
                                            </td>
                                        </tr>
                                    }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Row>
            <br></br>
        </Container>
    )
}

export default FormEditarRecordatorioRefeicao;

