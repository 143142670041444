// React
import { useForm } from "react-hook-form";

// React Bootstrap
import {
    Button,
    Form,
    Col,
    Row,
    Container,
    Card,
    FloatingLabel,
    Breadcrumb
} from 'react-bootstrap';

// Icone
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

// Service
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getUsuario } from "../../config/auth";
import { btnFull, errorMensagem } from '../../styled';

const validaForm = yup.object().shape({
    senha_atual: yup.string().required("Favor informe a senha atual"),
    nova_senha: yup.string().required("Favor informe a nova senha"),
    confirma_senha: yup.string().required("Favor informe a confirmação de senha")
});

function Perfil(){
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });

    const atualizarSenha = (data) => {
        data.email = getUsuario().email;
        if (data.nova_senha === data.confirma_senha && data.nova_senha.length >= 8) {
            api.post('/login/atualizarSenha', data)
            .then( (res) => {
                var msg = res.data.mensagem;
                alert(msg)
            })
            .catch( (err) => { 
                var msg = err.response.data.mensagem;
                alert(msg)
            })
        } else {
            alert("A nova senha digitada e sua confirmação não são idênticas ou possuem tamanho inferior ao limite mínimo de 8 (oito) caracteres.")
        }
    };

    return(
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Perfil</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Perfil</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Row>
                <Col md={7}>
                    <Card >
                        <Card.Body>
                            <Row>
                                <Form.Floating>
                                    <FloatingLabel 
                                        id="nome" 
                                        label="Nome Usuário">
                                        <Form.Control type="text" disabled name="nome" placeholder="Nome Usuário" value={getUsuario().nome}/>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Floating>
                                    <FloatingLabel 
                                        id="email" 
                                        label="Email">
                                        <Form.Control type="text" disabled name="email" placeholder="Email" value={getUsuario().email}/>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Row>
                            <br></br>
                            <Row>
                                <Form.Floating>
                                    <FloatingLabel 
                                        id="funcao" 
                                        label="Função">
                                        <Form.Control type="text" disabled name="funcao" placeholder="Função" value={getUsuario().funcao}/>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Row>
                            <br></br>         
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={5}>
                    <Card >
                        <Card.Body>
                            <Card.Text>Para alterar sua senha informe a senha atual e a nova senha que deseja utilizar para acessar o sistema!</Card.Text>
                            <Form onSubmit={handleSubmit(atualizarSenha)}> 
                                <Row>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="senha_atual" 
                                            label="Senha Atual">
                                            <Form.Control type="password" name="senha_atual" {...register("senha_atual")} placeholder="Senha Atual"/>
                                            <p style={ errorMensagem }>{ errors.senha_atual?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="nova_senha" 
                                            label="Nova Senha">
                                            <Form.Control type="password" name="nova_senha" {...register("nova_senha")} placeholder="Nova Senha"/>
                                            <p style={ errorMensagem }>{ errors.nova_senha?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="confirma_senha" 
                                            label="Confirma Senha">
                                            <Form.Control type="password" name="confirma_senha" {...register("confirma_senha")} placeholder="Confirma Senha"/>
                                            <p style={ errorMensagem }>{ errors.confirma_senha?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                    <Col>
                                        <Button id="my-btn-primary" style={ btnFull } className="btn-fill pull-right" type="submit" >
                                            <FontAwesomeIcon icon={faArrowsRotate} /> Alterar
                                        </Button>
                                    </Col>
                                </Row>
                                <div className="clearfix"></div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
};

export default Perfil;