import React from "react";
import { Col, Row } from "react-bootstrap";

const Pagination = ({ alimentosPorPage, totalAlimentos, paginate}) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalAlimentos / alimentosPorPage); i++){
        pageNumbers.push(i);
    }
    return (
        <>
        <nav>
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className='page-item'>
                        <a onClick={() => paginate(number) }  className="page-link">
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
        </>
    )
}

export default Pagination;