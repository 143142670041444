// React
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// React Bootstrap
import {
    Container,
    Row,
    Col,
    Form,
    FloatingLabel,
    Button,
    Breadcrumb,
    Card
} from "react-bootstrap";

// Icone
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

// Service
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { errorMensagem } from "../../styled";

const validaForm = yup.object().shape({
    identificador: yup.number().typeError('Favor informe o identificador da pessoa').required(),
    nome: yup.string().required("Favor informe o nome do pessoa"),
});

function FormEditarPessoa(){
    const { id_pessoa } = useParams();
    let history = useHistory();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validaForm)
    });
    
    const edtPessoa = (data) => { 
        api.patch("/pessoa/editar", data)
        .then( (res) => { 
            var msg = res.data.mensagem;
            alert(msg);
            history.push("/pessoas");
        })
        .catch( (err) => { 
            var msg = "Contate o administrador do sistema. \n\n ";
            msg += err.response.data.mensagem;
            alert(msg)
        })
    };

    useEffect(() => {
        api.get(`/pessoa/${ id_pessoa }`)
        .then((response) => {
            reset(response.data.pessoa)
        });

    }, []);

    return (
        <Container> 
            < br />
            <Row>
                <Col md={6}>
                    <h2>Editar Pessoa</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/pessoas">Pessoas</Breadcrumb.Item>
                        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            < br />
            <Row>
                <Card >
                    <Card.Body>
                        <Card.Text>Formulário para editar informações das pessoas que aceitram participar da pesquisa.</Card.Text>
                        <Form onSubmit={handleSubmit(edtPessoa)}> 
                            <Row>
                                <Col md={12}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="nome" 
                                            label="Nome do Pessoa">
                                            <Form.Control type="text" name="nome" {...register("nome")} placeholder="nome"/>
                                            <p style={ errorMensagem }>{ errors.nome?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Floating>
                                        <FloatingLabel 
                                            id="identificador" 
                                            label="Identificador">
                                            <Form.Control type="number" name="identificador" {...register("identificador")} placeholder="identificador"/>
                                            <p style={ errorMensagem }>{ errors.identificador?.message }</p>
                                        </FloatingLabel>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 4 }}>
                                    <Button id="my-btn-primary" style={{width: '100%'}} className="btn-fill pull-right" type="submit" >
                                        <FontAwesomeIcon icon={faSave} /> Atualizar
                                    </Button>
                                </Col>
                            </Row>
                            <div className="clearfix"></div>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default FormEditarPessoa;
