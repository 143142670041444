import logo from '../logo.svg';
//import '../App.css';
import { Link } from 'react-router-dom';

function logout() {
  localStorage.clear();
}

function Dashboard() {
  return (
    <>
    <br></br>
    <br></br>
      <h1>Em desenvolvimento ...</h1>
    </>
  );
}

export default Dashboard;
