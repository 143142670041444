// React Bootstrap
import { Container, Nav, Navbar  } from 'react-bootstrap';

//controle de exibição
import { logout, ac }  from "../../config/auth";
//import { useAuthStore } from "../../config/store";

import logo from '../../assets/img/logo2.png'

function Menu() {
  return (
    //<Navbar bg="dark" variant="dark" expand="lg" sticky="top"> 
    <Navbar bg="light" expand="lg" >
      <Container fluid>
        <Navbar.Brand href="/pessoas">
            <img src={logo} height={55} alt="Logo Diet Base"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                { ac().readAny('projeto').granted && (
                    <Nav.Item>
                        <Nav.Link href="/projetos">Gestão de Projetos</Nav.Link>
                    </Nav.Item>
                )}
                { ac().readAny('usuario').granted && (
                        <Nav.Item>
                            <Nav.Link href="/usuarios">Usuários</Nav.Link>
                        </Nav.Item>
                )}
                <Nav.Item>
                    <Nav.Link href="/pessoas">Pessoas</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/alimentos">Alimentos</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/receitas">Receitas</Nav.Link>
                </Nav.Item>
                { ac().readAny('relatorio').granted && (
                    <Nav.Item>
                        <Nav.Link href="/relatorios">Relatórios</Nav.Link>
                    </Nav.Item>
                )}
                {
                    <Nav.Item>
                        <Nav.Link href="/faq">FAQ</Nav.Link>
                    </Nav.Item>
                }
                <Nav.Item>
                    <Nav.Link href="/perfil">Perfil</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={logout}>Sair</Nav.Link>
                </Nav.Item>
            </Nav>    
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;