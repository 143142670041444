// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// React Bootstrap
import {
    Button,
    Table,
    Row,
    Col,
    Container,
    Form,
    FloatingLabel,
    Breadcrumb,
    Card,
    Alert
} from 'react-bootstrap';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

// Services
import api from "../../services/api";

// Outros
import { btnFull, colAcao } from '../../styled';

function ListaUsuarios(){
    const [usuarios, setUsuarios] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        const data = {
            nome: search
        };

        api.post("/usuario/", data)
        .then(response => {
            const data = response.data.usuarios;

            setUsuarios(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function deletaUsuario(id){
        console.log("Deletar usuario ", id)
        const user = usuarios.filter(usuario => usuario.id_usuario === id);
        if (window.confirm('Deseja realmente excluir o usuário '+user[0].nome+'?')) {
            console.log("Deletar usuario 2 ", id)
            api.delete(`/usuario/deletar/${id}`);
            setUsuarios(usuarios.filter(usuario => usuario.id_usuario !== id));
        }
    };
    
    return (
        <Container>
            < br />
            <Row>
                <Col md={6}>
                    <h2>Usuários</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Usuários</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={4}>
                    <Form.Floating>
                        <FloatingLabel label="Buscar por Nome">
                            <Form.Control type="text" id="buscar_usuario" name="buscar_usuario" placeholder="buscar_usuario" onChange={(e) => setSearch(e.target.value)}/>
                        </FloatingLabel>
                    </Form.Floating>
                </Col>
                <Col md={2}>
                <Link to={{ pathname: '/usuarios/addUsuarioProjeto/' }} >
                    <Button style={ btnFull } className="btn-fill pull-right" id="my-btn-primary" type="submit" > 
                        <FontAwesomeIcon icon={faPlus} />  Adicionar Usuários
                    </Button>
                </Link>
                </Col>
            </Row>
            < br />
            <Card >
                <Card.Header>
                    <Card.Title>Lista de usuários</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text></Card.Text>
                    {
                        (usuarios.length >= 1) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nome</th>
                                        <th>Email</th>
                                        <th>Função</th>
                                        <th>Projeto</th>
                                        <th style={ colAcao }>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usuarios.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{ item.id_usuario }</td>
                                                <td>{ item.nome }</td>
                                                <td>{ item.email }</td>
                                                <td>{ item.funcao }</td>
                                                <td>{ item.nome_projeto }</td>
                                                <td style={ colAcao }>
                                                    <Link to={{ pathname: `/usuarios/edtUsuarioProjeto/${item.id_usuario}` }} > 
                                                        <Button className="btn-fill pull-right" type="submit" id="my-btn-warning" > 
                                                            <FontAwesomeIcon icon={faPencil} />
                                                        </Button>
                                                    </Link>
                                                    <Button className="btn-fill pull-right" onClick={ () => deletaUsuario(item.id_usuario) } type="submit" id="my-btn-danger" >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                            )
                                    })}
                                </tbody>
                            </Table>
                        :
                            <Alert key='warning' variant='warning'>
                                Não possui dados cadastrados
                            </Alert>
                    }
                </Card.Body>
            </Card>
            <br></br>
        </Container>
    );
}

export default ListaUsuarios;