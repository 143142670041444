// React
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

// React Bootstrap
import {
    Button,
    Table,
    Row,
    Col,
    Form,
    Container,
    Modal,
    FloatingLabel,
    Card,
    Breadcrumb,
    Alert 
} from 'react-bootstrap';

// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faTrash, faSave, faPlus } from '@fortawesome/free-solid-svg-icons'

// Service
import api from "../../services/api";

// Outros
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { btnFull, colAcao, errorMensagem } from '../../styled';

const validaForm = yup.object().shape({
    nome_receita: yup.string().required("Favor informe o Nome da Receita"),
});

function ListaReceitas(){
    const [receitas, setReceitas] = useState([]);
    const [search, setSearch] = useState("");

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validaForm)
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const data = {
            nome: search
        };

        api.post("/receita", data)
        .then(response => {
            const data = response.data.receitas;

            setReceitas(data);
        })
        .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function deletaReceita(id){
        const receita = receitas.filter(receita => receita.id_receita === id);

        if (window.confirm('Deseja realmente excluir a receita '+receita[0].nome_receita+'?')) {
            api.delete(`/receita/deletar/${id}`);
            setReceitas(receitas.filter(receita => receita.id_receita !== id));
        }
        
    };

    const addReceita = (data) => { 
        api.post("/receita/cadastrar", data)
        .then( () => { 
            handleClose();
            document.location.reload();
        })
        .catch( () => { 
            alert("Contate o administrador do sistema")
        })
    };
    
    return (
        <Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Receita</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(addReceita)}> 
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <Form.Floating>
                                    <FloatingLabel 
                                        id="nome_receita" 
                                        label="Nome da Receita">
                                        <Form.Control type="text" name="nome_receita" {...register("nome_receita")} placeholder="nome_receita"/>
                                        <p style={ errorMensagem }>{ errors.nome_receita?.message }</p>
                                    </FloatingLabel>
                                </Form.Floating>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button id="my-btn-primary" className="btn-fill pull-right" type="submit" >
                                    <FontAwesomeIcon icon={faSave} /> Salvar
                                </Button>
                            </Col>
                        </Row>
                        <div className="clearfix"></div>
                    </Modal.Body>
                </Form>
            </Modal>

            < br />
            <Row>
                <Col md={6}>
                    <h2>Receitas</h2>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/pessoas">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Receitas</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col md={4}>
                    <Form.Floating>
                        <FloatingLabel label="Buscar por nome">
                            <Form.Control type="text" id="buscar_receita" name="buscar_receita" placeholder="buscar_receita" onChange={(e) => setSearch(e.target.value)}/>
                        </FloatingLabel>
                    </Form.Floating>
                </Col>
                <Col md={2}>
                    <Button style={ btnFull } id="my-btn-primary" className="btn-fill pull-right" type="submit" onClick={handleShow}> 
                        <FontAwesomeIcon icon={faPlus} /> Adicionar Receita
                    </Button>
                </Col>
            </Row>
            < br />
            <Card >
                <Card.Header>
                    <Card.Title>Lista de receitas</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text> </Card.Text>
                    {
                        (receitas.length >= 1) ?
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nome</th>
                                        <th style={ colAcao }>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {receitas.map((receita, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{ receita.id_receita }</td>
                                                <td>{ receita.nome_receita }</td>
                                                <td style={ colAcao }>
                                                    <Link to={{ pathname: `/listAlimentoReceita/${receita.id_receita}` }} > 
                                                        <Button id="my-btn-warning" className="btn-fill pull-right" type="submit" > 
                                                            <FontAwesomeIcon icon={faAdd} />
                                                        </Button>
                                                    </Link>
                                                    <Button id="my-btn-danger" className="btn-fill pull-right" onClick={ () => deletaReceita(receita.id_receita) } type="submit" >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                            )
                                    })}
                                </tbody>
                            </Table>
                    :
                            <Alert key='warning' variant='warning'>
                                Não possui dados cadastrados
                            </Alert>
                    }
                </Card.Body>
            </Card>
            <br></br>
        </Container>
    );
}

export default ListaReceitas;